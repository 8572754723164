import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { editStatus } from "../../redux/state/status";
import { useTranslation } from "react-i18next";

const FailLoginModal = () => {
  let dispatch = useDispatch();
  const {t} = useTranslation()

  const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );

  const closeFailLoginModal = () => {
    dispatch(
      editStatus({
        name: "isFailLoginModal",
        value: false,
      })
    );
  };

  return (
    <div
      style = {{fontFamily: "'Times New Roman', Times, serif"}}
      className={classNames(
        "fixed w-screen h-screen bg-zinc-800/90 flex justify-center items-center z-20"
      )}
    >
      <div className="w-90pc xs:w-64 small:w-96 medium:w-128 bg-white shadow-lg rounded-xl overflow-hidden">
        <div className="mt-6 content text-center text-sm small:text-base medium:text-lg">
          <h3 className="text-xl text-black">{t('loginFailed')}</h3>
        </div>
        <div>
          <button
              style={{
                  backgroundColor: botButtonColor
                      ? botButtonColor.slice(0, 7)
                      : '#0ea5e9', /* Defualt bg-sky-500 */
              }}
            onClick={closeFailLoginModal}
            type="button"
            className="flex justify-center items-center w-80pc px-4 py-2 rounded-lg mx-auto mb-6 mt-2 text-white font-bold shadow-lg"
          >
              {t('tryAgain')}
          </button>
          
        </div>
      </div>
    </div>
  );
};

FailLoginModal.propTypes = {};

export default FailLoginModal;
