import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { editStatus } from '../redux/state/status';
import classNames from 'classnames';

interface Props {
    debug?: string;
    setBannerHeight: any;
}

const Header = (props: Props) => {
    const bannerRef = useRef<null | HTMLDivElement>(null);

    const botColor = useSelector(
        (state: { settings: { botColor: string } }) => state.settings.botColor
    );
    const botBanner = useSelector(
        (state: { settings: { botBanner: string } }) => state.settings.botBanner
    );
    const botBannerPotraitMode = useSelector(
        (state: { settings: { botBannerPotraitMode: string } }) =>
            state.settings.botBannerPotraitMode
    );

    const isChatRatingSubmitted = useSelector(
        (state: { settings: { isChatRatingSubmitted: boolean } }) =>
            state.settings.isChatRatingSubmitted
    );

    const botCloseButtonColor = useSelector(
        (state: { settings: { botCloseButtonColor: string } }) =>
            state.settings.botCloseButtonColor
    );

    const botCloseButtonText = useSelector(
        (state: { settings: { botCloseButtonText: string } }) =>
            state.settings.botCloseButtonText
    );

    const botBannerRedirectUrl = useSelector(
        (state: { settings: { botBannerRedirectUrl: string } }) =>
            state.settings.botBannerRedirectUrl
    );

    const announcementBackgroundColor = useSelector(
        (state: { settings: { announcementBackgroundColor: string } }) =>
            state.settings.announcementBackgroundColor
    );

    const debugMessage = useSelector(
        (state: { session: { debugMessage: [string] } }) =>
            state.session.debugMessage
    );

    const [debug, setDebug] = useState('');

    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(
            editStatus({
                name: 'isChatRatingModal',
                value: true,
            })
        );
    };

    const handleBotBannerClick = () => {
        if (botBannerRedirectUrl) {
            window.open(botBannerRedirectUrl, '_blank');
        }
    };

    const setBanner = () => {
        if (bannerRef.current) {
            props.setBannerHeight(bannerRef.current.clientHeight);
        }
    };

    useEffect(() => {
        //viewport resize
        const handleResize = () => {
            if (bannerRef.current) {
                // console.log('Banner height', bannerRef.current.clientHeight);
                props.setBannerHeight(bannerRef.current.clientHeight);
            }
        };
        window.visualViewport?.addEventListener('resize', handleResize);
        return () => {
            window.visualViewport?.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div
            ref={bannerRef}
            style={{ backgroundColor: botColor ? botColor.slice(0, 7) : '' }}
            className="h-min medium:h-[80px] w-full rounded-t-xl flex justify-end items-center"
        >
            <p className="text-black bg-gray-300 absolute top-0 left-0 p-2 text-sm">
                {/* {props.debug} */}
                {/* {debugMessage.join('')} */}
            </p>
            {/* <a href={'https://www.google.com'} target="_blank" rel="noopener noreferrer"> */}
                <div
                    onClick={handleBotBannerClick}
                    className={`relative w-full h-min ${
                        botBannerRedirectUrl ? 'md:hover:cursor-pointer' : ''
                    }`}
                >
                    <div className="absolute top-0 left-0 w-full h-full z-50"></div>

                    <img
                        onClick={(e) => e.preventDefault()}
                        src={botBannerPotraitMode}
                        onLoad={setBanner}
                        alt=""
                        className="w-full h-auto object-contain block medium:hidden pointer-events-none"
                    />
                    <img
                        onClick={(e) => e.preventDefault()}
                        src={botBanner}
                        onLoad={setBanner}
                        alt=""
                        className="w-full h-[80px] object-fill hidden medium:block pointer-events-none"
                    />
                </div>
            {/* </a> */}
            {/* {!isChatRatingSubmitted && (
                <div
                    onClick={handleClick}
                    style={{ backgroundColor: `${botCloseButtonColor}` }}
                    className={classNames(
                        'p-2 mx-8 max-w-128 rounded-xl flex justify-center items-center z-10 shadow-lg shadow-inner',
                        {
                            'bg-red-600/75': !botCloseButtonColor,
                        }
                    )}
                >
                    {botCloseButtonText && (
                        <div className="text-white text-lg whitespace-nowrap">
                            {botCloseButtonText}
                        </div>
                    )}
                    {!botCloseButtonText && (
                        <FontAwesomeIcon
                            icon={faCircleXmark}
                            className={classNames('text-xl flex-none z-10', {
                                'text-red-600/75': !botCloseButtonColor,
                                'text-white': botCloseButtonColor,
                            })}
                        />
                    )}
                </div>
            )} */}
        </div>
    );
};

Header.propTypes = {};

export default Header;
