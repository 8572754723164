import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { editStatus } from "../../redux/state/status";
import { useTranslation } from "react-i18next";
import { faChevronLeft, faFileCircleQuestion, faCircleExclamation, faCirclePlus, faTrashCan, faXmark} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDocument } from 'pdfjs-dist';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import format from 'date-fns/format';
import { addChat } from '../../redux/state/chat';
import { ChatInterface } from '../../utils/interfaces';
import { useCacheService } from '../../hook/useCacheService';
import { useSocket } from '../../hook/useSocket';
import { getOS } from '../../utils/navigator';
import i18n from '../../i18n';


GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/5.0.375/pdf.worker.min.mjs`;

interface Props {
    height: number;
}

const UploadFileModal = (props: Props) => {
    const socket = useSocket();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const cacheService = useCacheService();

    const [isUploadDepositModalVisible, setIsUploadDepositModalVisible] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState<
        { id: string; file: File; thumbnail: string | null }[]
    >([]);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [previewType, setPreviewType] = useState<string | null>(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [fileToRemove, setFileToRemove] = useState<{ id: string; file: File } | null>(null);
    const [isSampleOpen, setSampleOpen] = useState(false);
    const [isAlertOpen, setIsAlertOpen]= useState(false)
    const [alertMessage, setAlertMessage]= useState<string | null>(null);
    const [failureMessage, setFailureMessage]= useState<string | null>(null);
    const [isFailUploadDepositModalOpen, setIsFailUploadDepositModalOpen] = useState(false);
    const [isSuccessUploadDepositModalOpen, setIsSuccessUploadDepositModalOpen] = useState(false);

    const language = i18n.language

    const isChatAlive = useSelector(
        (state: { status: { isChatAlive: Boolean } }) =>
            state.status.isChatAlive
    );

    const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );

    const botFontSize = useSelector(
        (state: { settings: { botFontSize: number } }) =>
            state.settings.botFontSize
    );

    const chatId = useSelector(
        (state: { session: { chatId: string } }) => state.session.chatId
    );

    const sessionId = useSelector(
        (state: { session: { sessionId: string } }) => state.session.sessionId
    );

    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );

    const brandCode = useSelector(
        (state: { settings: { brandCode: string } }) => state.settings.brandCode
    );

    const marketCode = useSelector(
        (state: { settings: { marketCode: string } }) => state.settings.marketCode
    );

    const transactionId = useSelector(
        (state: { session: { transactionId: string } }) => state.session.transactionId
    );

    const botName = useSelector(
        (state: { settings: {botName: string }}) => state.settings.botName
    );

    const apiPath = useSelector(
        (state: { session: { apiPath: string } }) => state.session.apiPath
    );

    const pluginPath = useSelector(
        (state: { session: { pluginPath: string } }) => state.session.pluginPath
    );

    const botId = useSelector(
        (state: { session: { botId: string } }) => state.session.botId
    );

    const botLanguage = useSelector(
        (state: { settings: { botLanguage: string } }) => state.settings.botLanguage
    );

    const processor = useSelector(
        (state: { settings: { processor: number } }) => state.settings.processor
    );
    
    const customValues = useSelector(
        (state: { settings: { customValues: any } }) => state.settings.customValues
    );
    
    let parsedValues = customValues ? JSON.parse(customValues) : {};
    const file_upload = parsedValues.hasOwnProperty('file_upload') ? JSON.parse(parsedValues.file_upload) : {};
    
    const default_max_files = 1;
    const max_file_number = file_upload.hasOwnProperty('max_files') && file_upload.max_files != null ? file_upload.max_files : default_max_files;

    const fontSizesRef = ['xs', 'sm', 'base', 'lg', 'xl'];
    const smallFontSizeRef = ['2xs', 'xs', 'sm', 'base', 'lg'];
    const fontSizes = [
        fontSizesRef[0 + botFontSize],
        fontSizesRef[1 + botFontSize],
        fontSizesRef[2 + botFontSize],
    ];
    const smallFontSizes = [
        smallFontSizeRef[0 + botFontSize],
        smallFontSizeRef[1 + botFontSize],
        smallFontSizeRef[2 + botFontSize],
    ];

    const closeUploadFileModal = () => {
        dispatch(editStatus({ name: "isUploadFileModal", value: false }));
    };

    const generateFileId = (file: File) => `${file.name}-${file.size}-${file.lastModified}`;

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);
    
        if (files.length + selectedFiles.length > max_file_number) {
            let alertMaxMessage = t("depositAlertMaxFiles", {max_file_number})
            showAlert(alertMaxMessage)
            event.target.value = ""; 
            return;
        }

        const validFileTypes = ["image/jpeg", "image/png", "image/gif", "application/pdf"];

        const largeFiles = files.filter(file => file.size >= 4 * 1024 * 1024);
        if (largeFiles.length > 0) {
            showAlert(t("depositAlertFileSize"));
            event.target.value = ""; 
            return;
    }
    
        const invalidFiles = files.filter(file => !validFileTypes.includes(file.type));
        if (invalidFiles.length > 0) {
            showAlert(t("depositAlertSupportType"));
            event.target.value = ""; 
            return;
        }

        const validFiles = files.filter((file) =>
            validFileTypes.includes(file.type) && file.size < 4 * 1024 * 1024
        );
    
        const duplicates = validFiles.filter(file => 
            selectedFiles.some(selected => selected.id === generateFileId(file))
        );
    
        if (duplicates.length > 0) {
            showAlert(t("depositAlertSameFile"));
            event.target.value = ""; 
            return;
        }

        // Generate thumbnails for PDFs
        const fileWithThumbnails = await Promise.all(
            validFiles.map(async (file) => {
                let thumbnail = null;
                if (file.type === "application/pdf") {
                    thumbnail = await generatePdfThumbnail(file);
                }
                return {
                    id: generateFileId(file),
                    file,
                    thumbnail,
                };
            })
        );

        setSelectedFiles((prevFiles) => [...prevFiles, ...fileWithThumbnails]);
    };

    const showAlert = (message: string) => {
        setAlertMessage(message);
        setIsAlertOpen(true);
    };

    const generatePdfThumbnail = async (pdfFile: File): Promise<string | null> => {
        try {
          const pdf = await getDocument(URL.createObjectURL(pdfFile)).promise;
          const page = await pdf.getPage(1);
          
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
      
          const viewport = page.getViewport({ scale: 1 });
          canvas.width = viewport.width;
          canvas.height = viewport.height;
      
          await page.render({ canvasContext: context!, viewport }).promise;
      
          return canvas.toDataURL();
        } catch (error) {
          console.error('Error generating PDF thumbnail:', error);
          return null;
        }
      }; 

    const openPreview = (file: File) => {
        setPreviewImage(URL.createObjectURL(file));
        setPreviewType(file.type.startsWith("image/") ? "image" : "pdf");
    };

    const closePreview = () => {
        setPreviewImage(null);
        setPreviewType(null);
    };

    const openSample = () => {
        setSampleOpen(true);
    };

    const closeSample= () => {
        setSampleOpen(false);
    };
    
    const removeFile = (fileToRemove: { id: string; file: File }) => {
        setSelectedFiles(selectedFiles.filter((file) => file.id !== fileToRemove.id));
    };

    const openConfirmModal = (fileToRemove: { id: string; file: File }) => {
        setFileToRemove(fileToRemove); // Set the file to remove when opening the modal
        setShowConfirmModal(true); 
    };

    const handleConfirmClick = () => {
        if (fileToRemove) {
            removeFile(fileToRemove);
        }
        setShowConfirmModal(false);
        setFileToRemove(null);
    };
    
    const closeConfirmModal = () => {
        setShowConfirmModal(false);
        setFileToRemove(null); // Set the file to remove when opening the modal
    };

    const openLoadingModal = () => {
        dispatch(
            editStatus({
                name: 'isLoadingModal',
                value: true,
            })
        );
    };

    const closeLoadingModal = () => {
        dispatch(
            editStatus({
                name: 'isLoadingModal',
                value: false,
            })
        );
    };

    const openSuccessUploadModal = () => {
        setIsSuccessUploadDepositModalOpen(true);
    };

    const closeSuccessUploadModal = () => {
        setIsSuccessUploadDepositModalOpen(false);
    };

    const openFailUploadModal = () => {
        const failureMessage = t('depositDefaultFailureMessage') 
        setFailureMessage(failureMessage);
        setIsFailUploadDepositModalOpen(true);
    };

    const closeFailUploadModal = () => {
        setFailureMessage(null);
        setIsFailUploadDepositModalOpen(false);
        closeUploadFileModal()
    };

    const submitUploadFile = async () => {
        openLoadingModal()
        setIsUploadDepositModalVisible(false)

        if (selectedFiles.length > 0) {
            const formData = new FormData();
            selectedFiles.forEach((file) => {
                formData.append('FileBase', file.file); 
            });
            formData.append('ChatId', chatId);
            formData.append('TransactionId', transactionId );
             
            let cacheInfo = {
                sessionId: botId.toString() + sessionId,
                cacheUrl: apiPath,
                botName: botName
            }

            try {
                const response = await fetch(`${pluginPath}/api/gloqoUploadFile`, {
                    method: 'POST',
                    headers: {
                        'x-brand-key': brandCode,
                    },
                    body: formData,
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                if (result[0].isSuccess){
                    const content = {
                        owner: 'user',
                        name: username,
                        type: 'text',
                        message: {
                            content: t("depositDefaultSuccessMessage"),
                        },
                        displayTime: format(new Date(), 'hh:mmaa'),
                        createdBy: new Date().getTime(),
                    } as ChatInterface;
                    cacheService.pushCache(
                        sessionId + '_chats',
                        JSON.stringify(content)
                    );
                    dispatch(addChat(content));
                    socket.sendUploadChatEvent({
                        chatId: chatId,
                        speaker: 'Client',
                        speakerName: username,
                        datetime: new Date().toJSON(),
                        message: t("depositDefaultSuccessMessage")
                    });
                    
                    let data = {
                        message: "Done (upload successful)",
                        type: 'message',
                        username: username,
                        chatId: chatId,
                        customer_lang_culture:
                            botLanguage.toLowerCase() === 'chinese'
                                ? 'zh-cn'
                                : botLanguage.toLowerCase() === 'thai'
                                ? 'th-th'
                                : botLanguage.toLowerCase() === 'vietnamese'
                                ? 'vi-vn'
                                : botLanguage.toLowerCase() === 'vietnam'
                                ? 'vi-vn'
                                : botLanguage.toLowerCase() === 'english'
                                ? 'en'
                                : botLanguage.toLowerCase(),
                        operating_system: getOS(navigator),
                        screen_resolution:
                            window.screen.width + '*' + window.screen.height,
    
                        platform: 'mario',
                        group_name: '',
                        processor: processor,
                        cacheInfo: cacheInfo,
                        brandInfo : {
                            brand: brandCode,
                            market: marketCode
                        },
                    };
                    socket.sendMessageEvent(data);
                    openSuccessUploadModal()
                } else {
                    const failureReason = result[0]?.message ? result[0].message : "Api Request Corrupted";
                    let data = {
                        message: `Done (upload not successful - ${failureReason})`,
                        type: 'message',
                        username: username,
                        chatId: chatId,
                        customer_lang_culture:
                            botLanguage.toLowerCase() === 'chinese'
                                ? 'zh-cn'
                                : botLanguage.toLowerCase() === 'thai'
                                ? 'th-th'
                                : botLanguage.toLowerCase() === 'vietnamese'
                                ? 'vi-vn'
                                : botLanguage.toLowerCase() === 'vietnam'
                                ? 'vi-vn'
                                : botLanguage.toLowerCase() === 'english'
                                ? 'en'
                                : botLanguage.toLowerCase(),
                        operating_system: getOS(navigator),
                        screen_resolution:
                            window.screen.width + '*' + window.screen.height,
    
                        platform: 'mario',
                        group_name: '',
                        processor: processor,
                        cacheInfo: cacheInfo,
                        brandInfo : {
                            brand: brandCode,
                            market: marketCode
                        },
                    };
                    socket.sendMessageEvent(data);
                    // dispatch(editStatus({ name: 'isBotTyping', value: false }))
                    openFailUploadModal()
                }
            } catch (error) {
                const failureReason = "System Error"
                let data = {
                    message: `Done (upload not successful - ${failureReason})`,
                    type: 'message',
                    username: username,
                    chatId: chatId,
                    customer_lang_culture:
                        botLanguage.toLowerCase() === 'chinese'
                            ? 'zh-cn'
                            : botLanguage.toLowerCase() === 'thai'
                            ? 'th-th'
                            : botLanguage.toLowerCase() === 'vietnamese'
                            ? 'vi-vn'
                            : botLanguage.toLowerCase() === 'vietnam'
                            ? 'vi-vn'
                            : botLanguage.toLowerCase() === 'english'
                            ? 'en'
                            : botLanguage.toLowerCase(),
                    operating_system: getOS(navigator),
                    screen_resolution:
                        window.screen.width + '*' + window.screen.height,

                    platform: 'mario',
                    group_name: '',
                    processor: processor,
                    cacheInfo: cacheInfo,
                    brandInfo : {
                        brand: brandCode,
                        market: marketCode
                    },
                };
                socket.sendMessageEvent(data);
                // dispatch(editStatus({ name: 'isBotTyping', value: false }))
                openFailUploadModal()
            }
            setSelectedFiles([]);
        }
        closeLoadingModal()
    };

    return (
        <>
            {isChatAlive && (
                <>
                    {isUploadDepositModalVisible && (
                        <div
                            style={{ height: props.height, fontFamily: "'Times New Roman', Times, serif" }}
                            className={classNames(
                                "fixed w-full h-full overflow-y-auto bg-zinc-800/90 flex justify-center items-center z-20"
                            )}
                        >
                            <div className={`h-full overflow-y-auto w-90pc xs:w-64 small:w-96 medium:w-128 text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]} bg-slate-200 shadow-lg overflow-hidden`}>
                                {/* Header */}
                                <div 
                                    className={`relative content text-center text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]} flex items-center justify-center text-white p-2 shadow-lg`}
                                    style={{ backgroundColor: botButtonColor ? botButtonColor.slice(0, 7) : '#0EA5E9' }}
                                >
                                    <button
                                        onClick={closeUploadFileModal}
                                        type="button"
                                        className="absolute left-8"
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} className="text-red" />
                                    </button>
                                    <h3>{t("depositUploadTitle")}</h3>
                                </div>
                                {/* AlertMessage */}
                                {isAlertOpen && alertMessage && (
                                    <div className="fixed inset-0 flex justify-center items-start z-50 mt-9">
                                        <div className="bg-white py-3 px-4 rounded-lg shadow-lg text-center relative">
                                            {/* Close Button */}
                                            <button
                                                onClick={() => setIsAlertOpen(false)}
                                                className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 text-xs"
                                            >
                                                <FontAwesomeIcon icon={faXmark} className="text-red-500" />
                                            </button>
                                            {/* Alert Content */}
                                            <div className="flex items-center space-x-2">
                                                <FontAwesomeIcon icon={faCircleExclamation} className="text-yellow-500" />
                                                <p>{alertMessage}</p>
                                            </div>
                                        </div>
                                </div>
                                )}
                                {/* File List */}
                                <div className="bg-white mx-auto mb-2 mt-2 max-h-90pc w-90pc rounded">
                                    <div className={`relative content text-left text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]} w-90pc mx-auto flex items-left justify-left py-2`}>
                                        <button
                                            onClick={openSample}
                                            type="button"
                                            className="absolute right-2 text-blue-500"
                                        >
                                            <FontAwesomeIcon icon={faFileCircleQuestion} />
                                            <span> {t("depositReceiptSample")}</span>
                                        </button>
                                        <h3>{t("depositReceipt")}</h3>
                                    </div>
                                    <div
                                        className={`text-${smallFontSizes[0]} small:text-${smallFontSizes[0]} medium:text-${smallFontSizes[1]} large:text-${smallFontSizes[2]} text-gray-600 w-90pc mx-auto flex items-left justify-left`}
                                    >
                                        <h3>{transactionId}</h3>
                                    </div>
                                    {selectedFiles.map((item, index) => (
                                        <div key={index} className="relative flex items-center py-2">
                                            <div
                                                className="flex items-center justify-center h-[18vh] w-90pc mx-auto cursor-pointer border"
                                            >
                                                {item.thumbnail ? (
                                                    <img
                                                        src={item.thumbnail}
                                                        alt="PDF Thumbnail"
                                                        className="max-h-full w-full object-cover"
                                                        style={{ objectPosition: "top" }}
                                                        onClick={() => openPreview(item.file)}
                                                    />
                                                ) : item.file.type.startsWith("image/") ? (
                                                    <img
                                                        src={URL.createObjectURL(item.file)}
                                                        alt="uploaded"
                                                        className="max-h-full w-full object-cover"
                                                        style={{ objectPosition: "top" }}
                                                        onClick={() => openPreview(item.file)}
                                                    />
                                                ) : (
                                                    <div className="h-full w-full bg-gray-200 flex items-center justify-center rounded">
                                                        <span className="text-gray-600">Unsupported File Type</span>
                                                    </div>
                                                )}
                                            </div>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openConfirmModal(item);
                                                }}
                                                className="absolute flex items-center justify-center border rounded-full bg-white p-2"
                                                style={{ right: "6.5%", top: "10%" }}
                                            >
                                                <FontAwesomeIcon icon={faTrashCan} className="text-red-500" />
                                            </button>
                                        </div>
                                    ))}
                                    <div className="py-1">
                                        {selectedFiles.length < max_file_number && (
                                            <label 
                                                className="flex justify-center items-center w-90pc px-4 py-2 border rounded-lg mx-auto mb font-bold shadow-lg"
                                                style={{
                                                    borderColor: botButtonColor ? botButtonColor.slice(0, 7) : '#0EA5E9',
                                                    color: botButtonColor ? botButtonColor.slice(0, 7) : '#0EA5E9'
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faCirclePlus} className="mr-2" />
                                                {t("upload")}
                                                <input
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    accept=".jpg,.jpeg,.png,.gif,.pdf"
                                                    multiple
                                                    className="hidden"
                                                />
                                            </label>
                                        )}
                                    </div>
                                    <div className={`text-${smallFontSizes[0]} small:text-${smallFontSizes[0]} medium:text-${smallFontSizes[1]} large:text-${smallFontSizes[2]} text-gray-600 relative content text-left w-90pc mx-auto flex items-left justify-left py-2`}>
                                        <div>
                                            {t('depositSupportFileType')}<br />
                                            {t('depositLargeFileSize')}<br />
                                            {t('depositUploadNumber', {max_file_number})}
                                        </div>
                                    </div>
                                </div>

                                {/* Submit Button */}
                                <div className="mx-auto mb-5 mt w-90pc">
                                    <button
                                        style={{
                                            backgroundColor: selectedFiles.length > 0 ?
                                                (botButtonColor? botButtonColor.slice(0, 7): 'skyblue')
                                                : '#cbd5e0', //Disabled  state, gray-200
                                            color : selectedFiles.length > 0 ? 'white' : 'grey' // Disable state, gray-350
                                        }}
                                        onClick={submitUploadFile}
                                        disabled={selectedFiles.length === 0} // Disable when no files are selected
                                        type="button"
                                        className={classNames(
                                            "flex justify-center items-center w-full py-2 rounded-lg mx-auto mb-6 mt-4 font-bold shadow-lg",
                                            {
                                                'cursor-not-allowed' : !selectedFiles, // Disabled state
                                            }
                                        )}
                                    >
                                        {t('submit')}
                                    </button>
                                </div>
                            </div>

                            {/* Preview Modal */}
                            {previewImage && (
                                <div className="fixed h-full overflow-y-auto inset-0 flex justify-center items-center bg-black/80 z-30">
                                    <div className="h-full overflow-y-auto relative w-90pc xs:w-64 small:w-96 medium:w-128 bg-slate-200 shadow-lg overflow-hidden">
                                        <div 
                                            style={{ backgroundColor: botButtonColor ? botButtonColor.slice(0, 7) : '#0EA5E9' }}
                                            className={`relative content text-center text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]} flex items-center justify-center text-white p-2 shadow-lg`}
                                        >
                                            <button onClick={closePreview} type="button" className="absolute left-8">
                                                <FontAwesomeIcon icon={faChevronLeft} className="text-red" />
                                            </button>
                                            <h3>{t('depositReceipt')}</h3>
                                        </div>
                                        <div className="relative flex items-center justify-center my-auto h-95pc w-full rounded mb-2">
                                            {previewType === "image" ? (
                                                <img
                                                    src={previewImage}
                                                    alt="Preview"
                                                    className="h-95pc w-90pc object-contain"
                                                />
                                            ) : (
                                                <iframe
                                                    src={previewImage}
                                                    className="h-95pc w-90pc object-contain"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Sample Modal */}
                            {isSampleOpen && (
                                <div className="fixed h-full inset-0 flex justify-center items-center bg-black/80">
                                    <div className="relative h-full overflow-y-auto w-90pc xs:w-64 small:w-96 medium:w-128 bg-slate-200 shadow-lg overflow-hidden">
                                        <div 
                                            style={{ backgroundColor: botButtonColor ? botButtonColor.slice(0, 7) : '#0EA5E9' }}
                                            className={`relative content text-center text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]} flex items-center justify-center text-white p-2 shadow-lg`}
                                        >
                                            <button onClick={closeSample} type="button" className="absolute left-8">
                                                <FontAwesomeIcon icon={faChevronLeft} className="text-red" />
                                            </button>
                                            <h3>{t("depositReceiptSample")}</h3>
                                        </div>
                                        <div className="relative flex items-center justify-center my-auto h-95pc w-full rounded mb-2">
                                            <img
                                                src={require(`../../assets/${brandCode.toLowerCase()}/${language.toLowerCase()}/deposit_sample_2x.png`)}
                                                alt="Preview"
                                                className="h-95pc w-90pc object-contain"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Confirm Remove Modal */}
                            {showConfirmModal && (
                                <div className="fixed h-full overflow-y-auto inset-0 flex justify-center items-center bg-black/80">
                                    <div className="w-90pc xs:w-64 small:w-96 medium:w-128 bg-white shadow-lg rounded-xl overflow-hidden">
                                        <div className="mt-4 content text-center text-sm small:text-base medium:text-lg">
                                            <h3 className={`text-xl text-black font-bold`}>{t("depositReminder")}</h3>
                                        </div>
                                        <div className="mt-4 content text-center text-sm small:text-base medium:text-lg">
                                            <h3 className="text-xl text-gray-600">{t("depositReminderDescription")}</h3>
                                        </div>
                                        <div>
                                            <button
                                                onClick={handleConfirmClick} 
                                                type="button"
                                                className="flex justify-center items-center w-85pc px-4 py-2 rounded-lg mx-auto mb-2 mt-6 text-white shadow-lg text-xl"
                                                style={{ backgroundColor: botButtonColor ? botButtonColor.slice(0, 7) : '#0EA5E9' }}
                                            >
                                                {t('confirm')}
                                            </button>
                                        </div>
                                        <div>
                                        <button
                                            onClick={closeConfirmModal}
                                            type="button"
                                            className="flex justify-center items-center w-85pc px-4 py-2 border rounded-lg mx-auto mb-6 mt-2 shadow-lg text-xl"
                                            style={{
                                                borderColor: botButtonColor ? botButtonColor.slice(0, 7) : '#0EA5E9',
                                                color: botButtonColor ? botButtonColor.slice(0, 7) : '#0EA5E9'
                                            }}
                                        >
                                            {t('back')}
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            )};
                        </div>
                    )}
                    {/* Success Upload Depost Modal */}
                    {isSuccessUploadDepositModalOpen && (
                        <div className="fixed inset-0 flex justify-center items-center bg-black/80">
                            <div className={`w-90pc xs:w-64 small:w-96 medium:w-128 bg-white shadow-lg rounded-xl overflow-hidden text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]}`}>
                                <div className="mt-6 content text-center text-sm small:text-base medium:text-lg">
                                    <h3 className="text-black">{t('depositSuccessTitle')}</h3>
                                </div>
                                <div>
                                    <button
                                        onClick={closeSuccessUploadModal}
                                        type="button"
                                        className="flex justify-center items-center w-80pc px-4 py-2 rounded-lg mx-auto mb-6 mt-4 text-white font-bold shadow-lg"
                                        style={{ backgroundColor: botButtonColor ? botButtonColor.slice(0, 7) : '#0EA5E9' }}
                                    >
                                        {t('Okay')}
                                </button>
                                </div>
                            </div>
                        </div>
                    )};
                    {/* Fail Upload Depost Modal */}
                    {isFailUploadDepositModalOpen && (
                        <div className="fixed inset-0 flex justify-center items-center bg-black/80">
                            <div className={`w-90pc xs:w-64 small:w-96 medium:w-128 bg-white shadow-lg rounded-xl overflow-hidden text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]}`}>
                                <div className="mt-6 content text-center text-sm small:text-base medium:text-lg">
                                    <h3 className="text-black">{t('depositFailureTitle')}</h3>
                                </div>
                                <div className="mt-4 content text-center text-sm small:text-base medium:text-lg">
                                    <h3 className="text-gray-600">{failureMessage}</h3>
                                </div>
                                <div>
                                    <button
                                        onClick={closeFailUploadModal}
                                        type="button"
                                        className="flex justify-center items-center w-80pc px-4 py-2 rounded-lg mx-auto mb-6 mt-4 text-white font-bold shadow-lg"
                                        style={{ backgroundColor: botButtonColor ? botButtonColor.slice(0, 7) : '#0EA5E9' }}
                                    >
                                        {t('Okay')}
                                </button>
                                </div>
                            </div>
                        </div>
                    )};
                </>
            )}
        </>    
    );   
};

export default UploadFileModal;
