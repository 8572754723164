import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Button from '../../component/Button';
import { useSocket } from '../../hook/useSocket';
import {
    JsFuntionInterface,
    ReduxFunctionInterface,
} from '../../utils/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCircleXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { da } from 'date-fns/locale';
import CacheService from '../../service/CacheService';
import { useCacheService } from '../../hook/useCacheService';
import Interweave from 'interweave';
import { useTranslation } from 'react-i18next';
import { editStatus } from '../../redux/state/status';
import { addDialog, editDialog } from '../../redux/state/dialog';
import { editSetting } from '../../redux/state/settings';
import Swal from 'sweetalert2';
import { editSession } from '../../redux/state/session';
import axios from 'axios';

interface Props {
    height: number;
}

const ChatRatingModal = (props: Props) => {
    const [commentText, setCommentText] = useState('');
    const [displayStar, setDisplayStar] = useState(0);
    const [ratedStar, setRatedStar] = useState(0);
    const [fieldRequiredStar, setFieldRequiredStar] = useState(false);
    const [fieldRequiredComment, setFieldRequiredComment] = useState(false);
    const [sizeOverLimit, setSizeOverLimit] = useState(false);
    const [fileupload, setFileUpload] = useState(false);
    const [file, setFile] = useState<any>();
    const [fileName, setFileName] = useState('');
    const [fileURL, setFileURL] = useState('');
    const emptyVal = "";
    const [checkSum, setCheckSum] = useState(0);
    const [content, setContent] = useState('');

    const dispatch = useDispatch();
    const { endSession, setCacheByField } = useCacheService();

    const { t } = useTranslation();

    const chatId = useSelector(
        (state: { session: { chatId: string } }) => state.session.chatId
    );

    const sessionId = useSelector(
        (state: { session: { sessionId: string } }) => state.session.sessionId
    );

    const acctId = useSelector(
        (state: { session: { acctId: string } }) => state.session.acctId
    );

    const botId = useSelector(
        (state: { session: { botId: string } }) => state.session.botId
    );

    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );

    const chatMode = useSelector(
        (state: { session: { chatMode: string } }) => state.session.chatMode
    );

    const isChatAlive = useSelector(
        (state: { status: { isChatAlive: boolean } }) =>
            state.status.isChatAlive
    );

    const isSessionAlive = useSelector(
        (state: { status: { isSessionAlive: boolean } }) =>
            state.status.isSessionAlive
    );

    const laAffinityToken = useSelector(
        (state: { session: { laAffinityToken: string } }) =>
            state.session.laAffinityToken
    );

    const laSessionKey = useSelector(
        (state: { session: { laSessionKey: string } }) =>
            state.session.laSessionKey
    );

    const laName = useSelector(
        (state: { session: { laName: string } }) =>
            state.session.laName
    );

    const postChatUrl = useSelector(
        (state: { session: {postChatUrl: string}}) =>
            state.session.postChatUrl
    )

    const isChatRatingModal = useSelector(
        (state: { status: { isChatRatingModal: boolean } }) =>
            state.status.isChatRatingModal
    );

    const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );

    const disableChatRatingSubmitted = useSelector(
        (state: { session: { disableChatRatingSubmitted: boolean } }) =>
            state.session.disableChatRatingSubmitted
    );

    const customValues = useSelector(
        (state: { settings: { customValues: string } }) => state.settings.customValues
    );


    const conversationId = useSelector(
        (state: { session: { conversationId: string } }) =>
            state.session.conversationId
    );

    const accessToken = useSelector(
        (state: { session: { accessToken: string } }) =>
            state.session.accessToken
    );

    let dataCustomValues = customValues ? JSON.parse(customValues) : {};
    const surveyCustomLink = dataCustomValues && dataCustomValues.hasOwnProperty('survey_link') ? dataCustomValues.survey_link : "";

    useEffect(() => {
        setCommentText('');
        setDisplayStar(0);
        setRatedStar(0);
        setFileUpload(false);
        if (chatMode == "agent" && isChatRatingModal == true) {
            setCheckSum(checkSum+1);
        }

        console.log("CHECKSUM HERE", checkSum);
        //setCheckSum(checkSum+1);
    }, [isChatRatingModal]);

    useEffect(() => {
        
        if (chatMode === 'agent' && !isSessionAlive) {
            console.log('end from chat rating')
            if (dataCustomValues['live_agent_mode'] && dataCustomValues['live_agent_mode'] == '1'){
                socket.sendChatEndEvent({
                    chatId: chatId,
                    conversationId: conversationId,
                    accessToken: accessToken,
                    liveAgentFlag: dataCustomValues['live_agent_mode']
                });
            }
            else {
                socket.sendChatEndEvent({
                    chatId: chatId,
                    affinityToken: laAffinityToken,
                    key: laSessionKey,
                });
            }
        }
    }, [isSessionAlive]);

    const chatrating = useSelector(
        (state: { systemMessages: { chatrating: { message: string } } }) =>
            state.systemMessages.chatrating.message
    );

    const chatratingFields = useSelector(
        (state: {
            systemMessages: {
                chatratingFields: {
                    fields: {
                        name: string;
                        type: 'rating' | 'textArea' | 'imageUpload';
                        scales?: {
                            '0': {
                                name: string;
                                text: string;
                                value: number;
                                isVisible: Boolean;
                            };
                            '1': {
                                name: string;
                                text: string;
                                value: number;
                                isVisible: Boolean;
                            };
                            '2': {
                                name: string;
                                text: string;
                                value: number;
                                isVisible: Boolean;
                            };
                            '3': {
                                name: string;
                                text: string;
                                value: number;
                                isVisible: Boolean;
                            };
                            '4': {
                                name: string;
                                text: string;
                                value: number;
                                isVisible: Boolean;
                            };
                        };
                        compulsory: Boolean;
                        isRequired: Boolean;
                    }[];
                };
            };
        }) => state.systemMessages.chatratingFields
    );

    const socket = useSocket();

    // useEffect(() => {
    //     setCommentText('');
    //     setDisplayStar(0);
    //     setRatedStar(0);
    //     setFileUpload(false);
    // }, [isChatRatingModal]);


    useEffect(() => {
        // if (chatMode === 'agent') {
        //     socket.sendChatEndEvent({
        //         chatId: chatId,
        //         affinityToken: laAffinityToken,
        //         key: laSessionKey,
        //     });
        // }
    }, [isChatAlive]);

    const handleStarHover = (rateNumber: number) => {
        setDisplayStar(rateNumber);
    };

    const handleStarClick = (rateNumber: number) => {
        setRatedStar(rateNumber);
    };

    const handleUpload = (e:any) => {

        let tempFile = e.target.files[0];
        let filesize = tempFile['size'];
        let fileSizeLimit = 1048576;

        if (filesize > fileSizeLimit){

            setSizeOverLimit(true);

        } else {
            setFile(e.target.files[0]);
            setFileURL(URL.createObjectURL(tempFile));
            setFileName(tempFile['name']);
            setSizeOverLimit(false);
            setFileUpload(true);
        }

        e.target.value = null;

    }

    const handleCancel = () => {
        setFieldRequiredComment(false);
        setFieldRequiredStar(false);

        setFile('');
        setFileURL('');
        setFileName('');
        setSizeOverLimit(false);
        setFileUpload(false);

        dispatch(
            editStatus({
                name: 'isChatRatingModal',
                value: false,
            })
        );
    };

    const checkFilled = (): boolean => {

        let checkRes = true;

        if(chatratingFields.fields.filter(field => field.type == "rating")[0]){
            if (chatratingFields.fields.filter(field => field.type == "rating")[0].isRequired && ratedStar < 1) {
                setFieldRequiredStar(true);
                checkRes = false;
            }else {
                setFieldRequiredStar(false);
            }
        }

        if(chatratingFields.fields.filter(field => field.type == "textArea")[0]){
            if (chatratingFields.fields.filter(field => field.type == "textArea")[0].isRequired && commentText == '') {
                setFieldRequiredComment(true);
                checkRes = false;
            }else{
                setFieldRequiredComment(false);
            }
        }
        

        return checkRes;

    }

    const handleXbuttonAgent = () => {

        handleCancel()

        let IsT1M1 = acctId.toString() + botId.toString();
        let T1M1ID = ["422", "430"];

        Swal.fire({
            title: '',
            text: t('ratingSubmitted'),
            icon: 'info',
            confirmButtonText: t('ratingEndChat'),
            confirmButtonColor: botButtonColor
                ? botButtonColor.slice(0, 7)
                : '',
            showCancelButton: true,
            cancelButtonText: t('ratingContinueChat'),
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(addDialog({ task: 'leaved', type: 'action' }));
                endSession(sessionId);
            }
        });


        // Swal.fire({
        //     title: '',
        //     text: T1M1ID.includes(IsT1M1) ? t('feedbackFormMessageAgentT1M1'): t('feedbackFormMessageAgent'),
        //     icon: 'info',
        //     confirmButtonText: t('feedbackFormAgent'),
        //     confirmButtonColor: botButtonColor
        //         ? botButtonColor.slice(0, 7)
        //         : '',
        //     showCancelButton: true,
        //     cancelButtonText: t('ratingContinueChat'),
        //     showDenyButton: true,
        //     denyButtonText: t('ratingEndChat'),
        // }).then((result) => {
        //     if (result.isConfirmed) {

        //         dispatch(
        //             editStatus({
        //                 name: 'isChatRatingModal',
        //                 value: true,
        //             })
        //         );

        //     } else if (result.isDenied) {

        //         dispatch(addDialog({ task: 'leaved', type: 'action' }));
        //         endSession(sessionId);

        //       }
        // });

    }

    const handleSubmit = () => {

        let check = checkFilled()

        if(check){
            let date = new Date().toLocaleString();

            if (fileupload == true) {

                let reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = function () {
                    let data = {
                        agentName: laName,
                        chatId: chatId,
                        customer_lang_culture: 'zh-cn',
                        rating: [
                            {
                                fieldName: 'Rating',
                                fieldType: 'rating',
                                fieldResponse: ratedStar.toString(),
                            },
                            {
                                fieldName: 'Comment',
                                fieldType: 'ratingType',
                                fieldResponse: commentText,
                            },
                            {
                                fieldName: 'imageUpload',
                                fieldType: 'imageUpload',
                                fieldResponse: [ 
                                    {
                                        file: reader.result,
                                        name: fileName,
                                    }
                                ]
                            }
                        ],
                        ratingDateTime: date,
                        type: 'ratingSubmit',
                        username: username,
                    };
                    
                    socket.sendChatRatingEvent(data);
    
                }

            } else{
                 let data = {
                    agentName: laName,
                    chatId: chatId,
                    customer_lang_culture: 'zh-cn',
                    rating: [
                        {
                            fieldName: 'Rating',
                            fieldType: 'rating',
                            fieldResponse: ratedStar.toString(),
                        },
                        {
                            fieldName: 'Comment',
                            fieldType: 'ratingType',
                            fieldResponse: commentText,
                        }
                    ],
                    ratingDateTime: date,
                    type: 'ratingSubmit',
                    username: username,
                };

                socket.sendChatRatingEvent(data);


            }


            handleCancel();
            //dispatch(addDialog({ task: 'leaved', type: 'action' }));
            // dispatch(
            //     editSetting({ name: 'isChatRatingSubmitted', value: true })
            // );
            //endSession(sessionId);

            dispatch(
                editSession({
                    name: 'chatRatingDone',
                    value: true,
                })
            );

            setCacheByField(sessionId, 'chatRatingDone', true);

            console.log('chatMode is', chatMode);
            if (disableChatRatingSubmitted != true){
                Swal.fire({
                    title: '',
                    text: t('ratingSubmitted'),
                    icon: 'success',
                    confirmButtonText: t('ratingEndChat'),
                    confirmButtonColor: botButtonColor
                        ? botButtonColor.slice(0, 7)
                        : '',
                    showCancelButton: true,
                    cancelButtonText: t('ratingContinueChat'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(addDialog({ task: 'leaved', type: 'action' }));
                        endSession(sessionId);
                    }
                });
            }
        }
    };

    // useEffect(() => {
    //     const fetchContent = async () => {
    //       try {
    //         const response = await axios.get(postChatUrl);
    //         setContent(response.data);
    //       } catch (error) {
    //         console.error("Error fetching content:", error);
    //       }
    //     };
    
    //     fetchContent();
    // }, [postChatUrl]);


    return (
        <div
            style={{ height: props.height }}
            onClick={handleCancel}
            className={classNames(
                // 'w-screen h-screen bg-zinc-800/90 z-20 flex items-center justify-center overflow-hidden',
                'fixed w-screen bg-zinc-800/90 z-20 flex items-center justify-center',
                { hidden: !isChatRatingModal }
            )}
        >

            { (chatMode === 'bot'|| surveyCustomLink === "mario")  && (
                <div onClick={(e) => {
                    e.stopPropagation();
                }}
                // className="p-8 rounded border bg-white w-full h-full medium:max-w-50pc medium:max-h-70pc chat--disapear active flex flex-wrap overflow-auto"
                className="fixed p-8 rounded border bg-white w-screen h-full medium:max-w-50pc medium:max-h-70pc chat--disapear active flex flex-wrap overflow-y-auto"
                >
                <h1 className="mt-8 font-medium text-base medium:text-xl text-center">
                    <Interweave content={chatrating} />
                </h1>

                <div className="mt-8 space-y-6">
                    <div id="rating-stars" className={classNames(
                    {hidden: !chatratingFields.fields.filter(field => field.type == "rating")[0]})
                    }>
                        <label className="text-sm medium:text-lg text-gray-700 block mb-1 font-medium">
                        {chatratingFields && chatratingFields.fields.filter(field => field.type == "rating")[0]? 
                            chatratingFields.fields.filter(field => field.type == "rating")[0].name:emptyVal}
                            <span 
                            className={classNames( "inline-block text-red-500 font-weight: bold",
                                {hidden: !chatratingFields.fields.filter(field => field.type == "rating")[0].isRequired})
                            }>
                                *
                            </span>

                            {fieldRequiredStar == true && (
                                <p className="ml-4 inline-block text-sm medium:text-lg text-red-500 mb-1 font-medium">
                                    {t('fillRequiredField')}
                                </p>
                            )}
                        </label>

                        <div className="">
                            <ul id="stars" className="flex">
                                <div className="group">
                                    <li
                                        onClick={() => {
                                            handleStarClick(1);
                                        }}
                                        onMouseOver={() => {
                                            handleStarHover(1);
                                        }}
                                        onMouseLeave={() => {
                                            handleStarHover(0);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faStar}
                                            className={classNames(
                                                displayStar > 0 || ratedStar > 0
                                                    ? 'text-yellow-500 font-medium text-3xl inline-block cursor-pointer'
                                                    : 'text-slate-300 font-medium text-3xl inline-block cursor-pointer'
                                            )}
                                        />
                                    </li>
                                </div>

                                <li
                                    onClick={() => handleStarClick(2)}
                                    onMouseOver={() => {
                                        handleStarHover(2);
                                    }}
                                    onMouseLeave={() => {
                                        handleStarHover(0);
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faStar}
                                        className={classNames(
                                            displayStar > 1 || ratedStar > 1
                                                ? 'text-yellow-500 font-medium text-3xl inline-block cursor-pointer'
                                                : 'text-slate-300 font-medium text-3xl inline-block cursor-pointer'
                                        )}
                                    />
                                </li>

                                <li
                                    onClick={() => handleStarClick(3)}
                                    onMouseOver={() => {
                                        handleStarHover(3);
                                    }}
                                    onMouseLeave={() => {
                                        handleStarHover(0);
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faStar}
                                        className={classNames(
                                            displayStar > 2 || ratedStar > 2
                                                ? 'text-yellow-500 font-medium text-3xl inline-block cursor-pointer'
                                                : 'text-slate-300 font-medium text-3xl inline-block cursor-pointer'
                                        )}
                                    />
                                </li>

                                <li
                                    onClick={() => handleStarClick(4)}
                                    onMouseOver={() => {
                                        handleStarHover(4);
                                    }}
                                    onMouseLeave={() => {
                                        handleStarHover(0);
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faStar}
                                        className={classNames(
                                            displayStar > 3 || ratedStar > 3
                                                ? 'text-yellow-500 font-medium text-3xl inline-block cursor-pointer'
                                                : 'text-slate-300 font-medium text-3xl inline-block cursor-pointer'
                                        )}
                                    />
                                </li>

                                <li
                                    onClick={() => handleStarClick(5)}
                                    onMouseOver={() => {
                                        handleStarHover(5);
                                    }}
                                    onMouseLeave={() => {
                                        handleStarHover(0);
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faStar}
                                        className={classNames(
                                            displayStar > 4 || ratedStar > 4
                                                ? 'text-yellow-500 font-medium text-3xl inline-block cursor-pointer'
                                                : 'text-slate-300 font-medium text-3xl inline-block cursor-pointer'
                                        )}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className={classNames(
                    {hidden: !chatratingFields.fields.filter(field => field.type == "textArea")[0]})
                    }>
                        <label className="text-sm medium:text-lg text-gray-700 block mb-1 font-medium">
                            
                        {chatratingFields && chatratingFields.fields.filter(field => field.type == "textArea")[0]? 
                            chatratingFields.fields.filter(field => field.type == "textArea")[0].name:emptyVal}


                            <span 
                            className={classNames( "inline-block text-red-500 font-weight: bold",
                                {hidden: !chatratingFields.fields.filter(field => field.type == "textArea")[0].isRequired})
                            }>
                                *
                            </span>

                            {fieldRequiredComment == true && (
                                <p className="ml-4 inline-block text-sm medium:text-lg text-red-500 mb-1 font-medium">
                                    {t('fillRequiredField')}
                                </p>
                            )}
                        </label>

                        <textarea
                            id="chatrating"
                            name="chatrating"
                            rows={4}
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                            className="display:block w-full bg-slate-300 rounded p-4"
                            maxLength={2000}
                        ></textarea>
                    </div>

                <div className={classNames(
                    {hidden: !chatratingFields.fields.filter(field => field.type == "imageUpload")[0]})
                    }>

                        <label className="text-sm medium:text-lg text-gray-700 block mb-1 font-medium">

                        {chatratingFields && chatratingFields.fields.filter(field => field.type == "imageUpload")[0]? 
                            chatratingFields.fields.filter(field => field.type == "imageUpload")[0].name:emptyVal}
                                

                            {sizeOverLimit == true && (
                                <p className="ml-1 inline-block text-sm medium:text-lg text-red-500 mb-1 font-medium">
                                    {t('ratingOverSizeLimit')}
                                </p>
                            )}

                        </label>

                        <div className={classNames('flex justify-center items-center w-full',{ hidden:fileupload })}>

                                <label className="flex flex-col justify-center items-center w-full bg-gray-50 
                                    rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 
                                    dark:bg-slate-600 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 
                                    dark:hover:bg-gray-600"
                                >
                                
                                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                            fill="currentColor">
                                            <path fill-rule="evenodd"
                                                d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 
                                                3 6 2-4 3 6z"
                                                clip-rule="evenodd" />
                                        </svg>

                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                <span className="font-semibold">
                                                    {t('ratingUpload1')}
                                                </span> 
                                            </p>
                                            <p className="text-xs text-gray-500 dark:text-gray-400">
                                                {t('ratingUpload2')}
                                            </p>

                                        </div>
                                        
                                        <input type="file" className="hidden" accept="image/*" onChange={handleUpload}/>
                                </label>

                        </div> 

                        <div className={classNames(
                                        'grid grid-cols-2 gap-4 mt-4 md:grid-cols-2',
                                        { hidden: !fileupload }
                                    )}>

                            <div className=
                                'relative items-center text-center bg-gray-100 border rounded'
                                >
                                <button className="absolute top-0 right-0 z-50 p-1 bg-white rounded-bl focus:outline-none" 
                                    type="button" onClick={()=> {setFileUpload(false)}}>
                                    <svg className="w-4 h-4 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>

                                <img className="relative inset-0 z-0 w-full h-full border-4 border-white preview"
                                src={fileURL}/>

                                <div className="absolute bottom-0 left-0 right-0 flex flex-col p-2 text-xs bg-white bg-opacity-50">
                                        <span className="w-full font-bold text-gray-900 truncate">{fileName}</span>
                                </div>

                            </div>
                            
                            
                        </div>
                        
                    </div>


                </div>

                <div className="space-x-4 mt-8 mb-12 flex flex-wrap">
                    <Button
                        // disabled={commentText == '' || ratedStar < 1}
                        style={{ backgroundColor: botButtonColor?.slice(0, 7) }}
                        className={classNames(
                            'py-2 px-4 text-white rounded hover:bg-violet-600 active:bg-blue-700 disabled:opacity-50',
                            {
                                'bg-violet-500': !botButtonColor,
                            }
                        )}
                        fns={
                            [
                                {
                                    type: 'js',
                                    func: handleSubmit,
                                },
                            ] as (JsFuntionInterface & ReduxFunctionInterface)[]
                        }
                    >
                        {t('submit')}
                    </Button>

                    <Button
                        className="py-2 px-4 bg-white border border-gray-200 text-gray-600 rounded 
                hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50"
                        fns={
                            [
                                {
                                    type: 'js',
                                    func: handleCancel,
                                },
                            ] as (JsFuntionInterface & ReduxFunctionInterface)[]
                        }
                    >
                        {t('cancel')}
                    </Button>
                </div>
                </div>     

            )

            }

            { (checkSum == 1 && chatMode === 'agent' && surveyCustomLink !== "mario") && (
                <div onClick={(e) => {
                    e.stopPropagation();
                }}
                // className="p-8 rounded border bg-white w-full h-full medium:max-w-50pc medium:max-h-70pc chat--disapear active flex flex-wrap overflow-auto"
                className="fixed p-8 rounded border bg-white w-screen h-full medium:max-w-50pc medium:max-h-70pc chat--disapear active flex flex-wrap overflow-y-auto"
                >
                    <FontAwesomeIcon 
                            icon={faXmarkCircle}
                            className={`absolute top-0 right-0 p-2 text-2xl`}
                            onClick={handleXbuttonAgent}
                        />

                    <iframe className='w-full h-full bg-slate-200 ' loading="lazy" src={postChatUrl}/>
                    {/* <div className="w-full h-full bg-slate-200 p-4">
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                        </div> */}

                </div>   
            )}

            { (checkSum > 1 && chatMode === 'agent' && surveyCustomLink !== "mario") && (
                <div onClick={(e) => {
                    e.stopPropagation();
                }}
                // className="p-8 rounded border bg-white w-full h-full medium:max-w-50pc medium:max-h-70pc chat--disapear active flex flex-wrap overflow-auto"
                className="fixed p-8 rounded border bg-white w-screen h-full medium:max-w-50pc medium:max-h-70pc chat--disapear active flex flex-wrap overflow-y-auto"
                >
                    <FontAwesomeIcon 
                            icon={faXmarkCircle}
                            className={`absolute top-0 right-0 p-2 text-2xl`}
                            onClick={handleXbuttonAgent}
                        />

                    <iframe className='w-full h-full bg-slate-200 ' key={checkSum} src={postChatUrl}/>
                    {/* <div className="w-full h-full bg-slate-200 p-4">
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                        </div> */}

                </div>   
            )}



        </div>
    );
};

ChatRatingModal.propTypes = {};

export default ChatRatingModal;


// chatRatingFields.fields.filter(field => field.type == "textArea")[0].name
