import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editSession } from '../../redux/state/session';
import classNames from 'classnames';
import { checkUsername } from '../../helper/common';
import { error, warning } from '../../utils/console';
import { useCacheService } from '../../hook/useCacheService';
import { useDataService } from '../../hook/useDataService';
import { useSocket } from '../../hook/useSocket';
import { editStatus } from '../../redux/state/status';
import axios from 'axios';
import forge from 'node-forge';
import { useTranslation } from 'react-i18next';
import { ChatInterface, SendIntentEventInterface, SendMessageEventInterface } from '../../utils/interfaces';
import { getOS } from '../../utils/navigator';
import StagingConfig from '../../config/staging.config.json';
import ProductionConfig from '../../config/production.config.json';
import { getEncrypt64 } from '../../utils/encryptionModals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUnlockKeyhole } from '@fortawesome/free-solid-svg-icons';
import format from 'date-fns/format';
import { addChat } from '../../redux/state/chat';

interface Props {
    height: number;
}
var config: any;
var HashScriptLink = '';

const LoginModal = (props: Props) => {
    const socket = useSocket();
    const { setCacheByField } = useCacheService();
    const { t } = useTranslation();

    const isLoginModal = useSelector(
        (state: { status: { isLoginModal: Boolean } }) =>
            state.status.isLoginModal
    );

    const cacheService = useCacheService();

    const dataService = useDataService();

    const [loginUsername, setLoginUsername] = useState('');

    const [loginPassword, setLoginPassword] = useState('');

    const usernamePlaceholder = t('loginUsername');

    const passwordPlaceholder = t('loginPassword');

    const handleUsernameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setLoginUsername(event.target.value);
    };

    const handlePasswordChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setLoginPassword(event.target.value);
    };

    const dispatch = useDispatch();

    const closeLoginModal = () => {
        dispatch(
            editStatus({
                name: 'isLoginModal',
                value: false,
            })
        );
    };

    const openLoadingModal = () => {
        dispatch(
            editStatus({
                name: 'isLoadingModal',
                value: true,
            })
        );
    };

    const closeLoadingModal = () => {
        dispatch(
            editStatus({
                name: 'isLoadingModal',
                value: false,
            })
        );
    };

    const openSuccessLoginModal = () => {
        dispatch(
            editStatus({
                name: 'isSuccessLoginModal',
                value: true,
            })
        );
    };

    const openFailLoginModal = () => {
        dispatch(
            editStatus({
                name: 'isFailLoginModal',
                value: true,
            })
        );
    };

    const isChatAlive = useSelector(
        (state: { status: { isChatAlive: Boolean } }) => state.status.isChatAlive
    );

    const sessionId = useSelector(
        (state: { session: { sessionId: string } }) => state.session.sessionId
    );

    const chatId = useSelector(
        (state: { session: { chatId: string } }) => state.session.chatId
    );

    const acctId = useSelector(
        (state: { session: { acctId: string } }) => state.session.acctId
    );

    const botId = useSelector(
        (state: { session: { botId: string } }) => state.session.botId
    );

    const dbProfile = useSelector(
        (state: { session: { dbProfile: string } }) => state.session.dbProfile
    );

    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );

    const intentId = useSelector(
        (state: { status: { intentId: string } }) => state.status.intentId
    );

    const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );

    const botLanguage = useSelector(
        (state: { settings: { botLanguage: string } }) =>
            state.settings.botLanguage
    );

    const loginForgotPasswordLinkColor = useSelector(
        (state: { settings: { loginForgotPasswordLinkColor: string } }) =>
            state.settings.loginForgotPasswordLinkColor
    );

    const processor = useSelector(
        (state: { settings: { processor: number } }) =>
            state.settings.processor
    );

    const brandCode = useSelector(
        (state: { settings: { brandCode: string } }) => state.settings.brandCode
    )

    const marketCode = useSelector(
        (state: { settings: { marketCode: string } }) => state.settings.marketCode
    )

    const customValues = useSelector(
        (state: { settings: { customValues: string } }) =>
            state.settings.customValues
    );

    const vip = useSelector(
        (state: { session: { isVip: any } }) => state.session.isVip
    );

    const botName = useSelector(
        (state: { settings: {botName: string}}) =>
            state.settings.botName
    );

    const apiPath = useSelector(
        (state: { session: { apiPath: string } }) =>
            state.session.apiPath
    );

    const pluginPath = useSelector(
        (state: { session: { pluginPath: string } }) => state.session.pluginPath
    );

    let parsedCustomValues = customValues ? JSON.parse(customValues) : {};
    const showForgotPassword = parsedCustomValues.hasOwnProperty('forgot_password_link') ? parsedCustomValues.forgot_password_link : "";
    
    const failureMessageProcess = (failDescription: string) => {
        // const content = {
        //     owner: 'bot',
        //     name: botName,
        //     type: 'text',
        //     message: {
        //         content: failDescription,
        //     },
        //     displayTime: format(new Date(), 'hh:mmaa'),
        //     createdBy: new Date().getTime(),
        // } as ChatInterface;
        // cacheService.pushCache(
        //     sessionId + '_chats',
        //     JSON.stringify(content)
        // );
        // dispatch(addChat(content));
        // socket.sendUploadChatEvent({
        //     chatId: chatId,
        //     speaker: 'Bot',
        //     speakerName: botName,
        //     datetime: new Date().toJSON(),
        //     message: failDescription
        // });
        let data = {
            message: "Done (login not successful)",
            type: 'message',
            username: username,
            chatId: chatId,
            customer_lang_culture:
                botLanguage.toLowerCase() === 'chinese'
                    ? 'zh-cn'
                    : botLanguage.toLowerCase() === 'thai'
                    ? 'th-th'
                    : botLanguage.toLowerCase() === 'vietnamese'
                    ? 'vi-vn'
                    : botLanguage.toLowerCase() === 'vietnam'
                    ? 'vi-vn'
                    : botLanguage.toLowerCase() === 'english'
                    ? 'en'
                    : botLanguage.toLowerCase(),
            operating_system: getOS(navigator),
            screen_resolution:
                window.screen.width + '*' + window.screen.height,

            platform: 'mario',
            group_name: '',
            processor: processor,
            brandInfo : {
                brand: brandCode,
                market: marketCode
            },
        };
        socket.sendMessageEvent(data);
        // dispatch(editStatus({ name: 'isBotTyping', value: false }))
    };

    const botConfig = async () => {
        // if (dbProfile === 'mario.production') {
        //     return ProductionConfig.find(config => config.botId === parseInt(botId) && config.accountId === parseInt(acctId));
        // } else {
        //     return StagingConfig.find(config => config.botId === parseInt(botId) && config.accountId === parseInt(acctId));
        // }
        const clientApi = process.env.REACT_APP_DEPLOY_ENV === 'local'
        ? 'http://localhost:3001/getBotConfig'
        : `${window.location.origin}/nodeApi/getBotConfig`;

        const requestBody = {
            accountId: parseInt(acctId),
            botId: parseInt(botId),
        };

        try{

            const response = await fetch(clientApi, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const configData = await response.json();
            return configData;
        }
        catch (error) {
            console.error('Error fetching bot config:', error);
            // Handle the error (e.g., return null or a default config)
            return null;
            }
    }
    // const dynamicScript = (callback: any) => {
    //     const existingScript = document.getElementById('dynamicjs');
    //     if (!existingScript) {
    //         const script = document.createElement('script');
    //         script.src = HashscriptUrl;
    //         script.id = 'dynamicjs';
    //         document.body.appendChild(script);
    //         script.onload = () => {
    //             console.log('script loaded!!!');
    //             callback()
    //         }
    //     }
    // };
    // useEffect(() => {
    //     dynamicScript(() => {
    //         window.encryptValue(loginPassword)
    //     });
    // }, []);

    // const ProcessLogin = async () => {
    //     openLoadingModal();
    //     closeLoginModal();

    //     const config = await botConfig();

    //     const Params = {
    //         access_token: config?.access_token,
    //         session_id: chatId,
    //     };
    //     axios.post(`${config?.boPath}/api/initProcess`, Params).then((res) => {
    //         const data = res.data;
    //         const ScriptName = data.script_name;
    //         const RefreshToken = data.refresh_token;
    //         const ScriptUrl = `${config?.boPath}/api/script/${ScriptName}`;
    //         axios.post(ScriptUrl, Params).then((res) => {
    //             const data = res.data;
    //         });
    //         const LoginJson = {
    //             date_time_format: 'yy/mm/dd hh:MM:ss',
    //             password: loginPassword,
    //             refresh_token: RefreshToken,
    //             script_name: ScriptName,
    //             user_name: loginUsername,
    //         };
    //         const HashscriptUrl = `${config?.boPath}/api/hashScript/${ScriptName}`
    //         axios.post(HashscriptUrl, LoginJson).then(function (response) {
    //             const HashscriptText = response.data;
    //             HashScriptLink = `${config?.boPath}/api/getHashscript/${ScriptName}`
    //             const existingScript = document.getElementById('dynamicjs');
    //             // console.log(existingScript);
    //             const doEncryption = () => {
    //                 const CryptoJS = require('crypto-js');
    //                 const getEncryptValue = window.encryptValue(
    //                     LoginJson.password,
    //                     forge,
    //                     CryptoJS
    //                 );
    //                 getEncryptValue.then((result: any) => {
    //                     const securePasswordHash = result;
    //                     console.log(
    //                         'Encrypted Password: ',
    //                         securePasswordHash
    //                     );
    //                     const submitLoginUrl = `${config?.boPath}/api/submitPasswordHash`;
    //                     const submitLoginJson = {
    //                         acct_id: acctId.toString(),
    //                         bot_id: botId.toString(),
    //                         customer_language_culture: 'zh-cn',
    //                         refresh_token: RefreshToken,
    //                         secured_password_hash: securePasswordHash,
    //                         session_id: chatId,
    //                         user_name: loginUsername,
    //                     };
    //                     console.log('submitLoginJson:', submitLoginJson, 'url:', `${config?.boPath}/api/submitPasswordHash`);
    //                     axios
    //                         .post(submitLoginUrl, submitLoginJson)
    //                         .then(function (response) {
    //                             const loginResponse = response.data;
    //                             console.log("RESPONSE IS HERE",response);
    //                             closeLoadingModal();
    //                             if (loginResponse.login_status) {
    //                                 // openSuccessLoginModal();
    //                                 console.log('SUCCESS LOGIN');
    //                                 let data = {
    //                                     username: username,
    //                                     chatId: chatId,
    //                                     type: 'goToIntent',
    //                                     intentId: intentId,
    //                                     triggerTime: new Date().toJSON(),
    //                                     platform: 'mario',
    //                                     group_name: '',
    //                                     customer_lang_culture:
    //                                         botLanguage.toLowerCase() ===
    //                                         'chinese'
    //                                             ? 'zh-cn'
    //                                             : botLanguage.toLowerCase() ===
    //                                               'thai'
    //                                             ? 'th-th'
    //                                             : botLanguage.toLowerCase() ===
    //                                               'vietnamese'
    //                                             ? 'vi-vn'
    //                                             : botLanguage.toLowerCase() ===
    //                                               'vietnam'
    //                                             ? 'vi-vn'
    //                                             : botLanguage.toLowerCase() ===
    //                                               'english'
    //                                             ? 'en'
    //                                             : botLanguage.toLowerCase(),
    //                                     operating_system: getOS(navigator),
    //                                     screen_resolution:
    //                                         window.screen.width +
    //                                         '*' +
    //                                         window.screen.height,
    //                                     processor: processor
    //                                 } as SendIntentEventInterface;
    //                                 socket.sendIntentEvent(data);
    //                             } else {
    //                                 openFailLoginModal();
    //                                 console.log('LOGIN FAILED');
    //                             }
    //                         });
    //                 });
    //             };
    //             if (!existingScript) {
    //                 const script = document.createElement('script');
    //                 script.src = HashScriptLink;
    //                 script.id = 'dynamicjs';
    //                 document.body.appendChild(script);
    //                 script.onload = () => {
    //                     console.log('script loaded!!!');
    //                     doEncryption();
    //                 };
    //             } else {
    //                 doEncryption();
    //             }
    //         });
    //     });
    // };
    const ProcessLogin = async () => {
        openLoadingModal();
        closeLoginModal();

        // const config = await botConfig();

        const authPublicKeyUrl = `${pluginPath}/api/getAuthPublicKey`;
        axios.post(authPublicKeyUrl).then(function(response){
            const authPublicKey = response.data.public_key
            const LoginParams = {
                user_name : loginUsername,
                secured_password_hash : loginPassword,
                acct_id: acctId.toString(),
                bot_id: botId.toString(),
                session_id: chatId,
                is_vip: vip[0]
            }

            console.log("LoginParams: ", LoginParams)
            
            const loginParamsEncryted = getEncrypt64(LoginParams, authPublicKey)
            const submitLoginUrl = `${pluginPath}/api/submitPasswordHash`;
                            
            const submitLoginJson = {
                valueEncryted: loginParamsEncryted
            };
            console.log('submitLoginJson:', submitLoginJson, 'url:', `${pluginPath}/api/submitPasswordHash`);
            axios
                .post(submitLoginUrl, submitLoginJson)
                .then(function (response) {
                    const loginResponse = response.data;
                    console.log("RESPONSE IS HERE",response);
                    closeLoadingModal();
                    if (loginResponse.login_status) {
                        let cacheInfo = {
                            sessionId: botId.toString() + sessionId,
                            cacheUrl: apiPath,
                            botName: botName
                        }
                        if (processor == 2){
                            let data = {
                                message: "Done (login successful)",
                                type: 'message',
                                username: username,
                                chatId: chatId,
                                customer_lang_culture:
                                    botLanguage.toLowerCase() === 'chinese'
                                        ? 'zh-cn'
                                        : botLanguage.toLowerCase() === 'thai'
                                        ? 'th-th'
                                        : botLanguage.toLowerCase() === 'vietnamese'
                                        ? 'vi-vn'
                                        : botLanguage.toLowerCase() === 'vietnam'
                                        ? 'vi-vn'
                                        : botLanguage.toLowerCase() === 'english'
                                        ? 'en'
                                        : botLanguage.toLowerCase(),
                                operating_system: getOS(navigator),
                                screen_resolution:
                                    window.screen.width + '*' + window.screen.height,
            
                                platform: 'mario',
                                group_name: '',
                                processor: processor,
                                cacheInfo: cacheInfo,
                                brandInfo : {
                                    brand: brandCode,
                                    market: marketCode
                                },
                            };
                            socket.sendMessageEvent(data);
                            dispatch(editSession({ name: 'username', value: loginUsername }));
                            setCacheByField(sessionId, 'username', loginUsername);
                            dispatch(editSession({ name: 'waitingAnswerReply', value: true }));
                            cacheService.setCacheByField(sessionId, 'waitingAnswerReply', true)
                            openSuccessLoginModal();
                            socket.sendCheckVipEvent({
                                chatId: chatId,
                                username: loginUsername,
                                usernameFromUrl: false, //check
                                recheckVip: true,
                                checkVipDetails: parsedCustomValues['check_vip_details'] ? parsedCustomValues['check_vip_details'] : null
                            })
                        console.log('SUCCESS LOGIN');
                        } 
                        if (processor == 0){
                            let cacheInfo = {
                                sessionId: botId.toString() + sessionId,
                                cacheUrl: apiPath,
                                botName: botName
                            }
                            let data = {
                                username: username,
                                chatId: chatId,
                                type: 'goToIntent',
                                intentId: intentId,
                                triggerTime: new Date().toJSON(),
                                platform: 'mario',
                                group_name: '',
                                customer_lang_culture:
                                    botLanguage.toLowerCase() ===
                                    'chinese'
                                        ? 'zh-cn'
                                        : botLanguage.toLowerCase() ===
                                        'thai'
                                        ? 'th-th'
                                        : botLanguage.toLowerCase() ===
                                        'vietnamese'
                                        ? 'vi-vn'
                                        : botLanguage.toLowerCase() ===
                                        'vietnam'
                                        ? 'vi-vn'
                                        : botLanguage.toLowerCase() ===
                                        'english'
                                        ? 'en'
                                        : botLanguage.toLowerCase(),
                                operating_system: getOS(navigator),
                                screen_resolution:
                                    window.screen.width +
                                    '*' +
                                    window.screen.height,
                                processor: processor,
                                cacheInfo: cacheInfo,
                                brandInfo : {
                                    brand: brandCode,
                                    market: marketCode
                                }
                            } as SendIntentEventInterface;
                            socket.sendIntentEvent(data);
                            dispatch(editSession({ name: 'waitingAnswerReply', value: true }));
                            setCacheByField(sessionId, 'waitingAnswerReply', true)
                        };    
                    } else {
                        if (processor == 2){
                            if (loginResponse.description == "[ERROR_ON_LOGIN]"){
                                loginResponse.description = t('error_login')
                            }
                            failureMessageProcess(loginResponse.description)
                        }
                        openFailLoginModal();
                        console.log('LOGIN FAILED');
                    }
                })
                .catch(function (error) {
                    console.error('Error during login:', error);
                    const failDescription = t('error_login')
                    failureMessageProcess(failDescription)
                    openFailLoginModal();
                })
                .finally(function () {
                    closeLoadingModal();
                });
        })
        .catch(function (error) {
            console.error('Error while getting public key:', error);
            const failDescription = t('error_login')
            failureMessageProcess(failDescription)
            closeLoadingModal();
            openFailLoginModal();
        })
    };
    return (
        <>
            {isChatAlive && (
                <div
                    style={{ 
                        height: props.height, 
                        fontFamily: "'Times New Roman', Times, serif", 
                    }}
                    className={classNames(
                        'fixed w-screen h-screen bg-zinc-800/90 flex justify-center items-center z-20'
                    )}
                >
                    <div className="w-90pc xs:w-64 small:w-96 medium:w-128 bg-white shadow-lg rounded-xl overflow-hidden">
                        <div className="flex justify-center items-center p-4 rounded-t">
                            <h3 className="text-xl text-black font-bold">{t('loginTitle')}</h3>
                        </div>
                        <div className="content text-center text-sm small:text-base medium:text-lg">
                            <div className="relative mb-2 mt-2 w-90pc mx-auto">
                                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-300">
                                    <FontAwesomeIcon icon={faUser} />
                                </span>
                                <input
                                    onChange={handleUsernameChange}
                                    className={classNames(
                                        "pl-10 pr-4 py-3 w-full rounded-lg text-gray-800 focus:outline-none shadow-lg border border-gray-300"
                                    )}
                                    placeholder={usernamePlaceholder}
                                    value={loginUsername}
                                    type="text"
                                />
                            </div>

                            <div className="relative mb-5 mt-2 w-90pc mx-auto">
                                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-300">
                                    <FontAwesomeIcon icon={faUnlockKeyhole} className="text-lg" />
                                </span>
                                <input
                                    onChange={handlePasswordChange}
                                    className={classNames(
                                        "pl-10 pr-4 py-3 w-full rounded-lg text-gray-800 focus:outline-none shadow-lg border border-gray-300"
                                    )}                            
                                    placeholder={passwordPlaceholder}
                                    value={loginPassword}
                                    type="password"
                                />
                            </div>
                        </div>
                        {showForgotPassword && (
                            <div className="relative mb-1 mt-1 w-90pc mx-auto text-right">
                                <a 
                                    href={showForgotPassword}
                                    className="hover:underline"
                                    style={{ 
                                        fontSize: '85%',
                                        color: `${loginForgotPasswordLinkColor ? loginForgotPasswordLinkColor.slice(0, 7) : '#0EA5E9'}`,
                                    }}
                                >
                                    {t('forgotPassword')}
                                </a>
                            </div>
                        )}
                        <div>
                            <button
                                style={{
                                    backgroundColor: loginUsername && loginPassword ?
                                        (botButtonColor? botButtonColor.slice(0, 7): 'skyblue')
                                        : '#e2e8f0', //Disabled  state, gray-200
                                    color : loginUsername && loginPassword ? 'white' : '#cbd5e0' // Disable state, gray-350
                                }}
                                onClick={ProcessLogin}
                                disabled={!loginUsername || !loginPassword}
                                type="button"
                                className={classNames(
                                    'flex justify-center items-center w-90pc px-4 py-3 rounded-lg mx-auto mb-2 mt-5 font-bold shadow-lg',
                                    {
                                        'cursor-not-allowed' : !loginUsername || !loginPassword, // Disabled state
                                    }
                                )}
                            >
                                {t('login')}
                            </button>
                        </div>
                        <div>
                            <button
                                style={{
                                    border: `1px solid ${botButtonColor ? botButtonColor.slice(0, 7) : ''}`, // Use your desired color
                                    backgroundColor: 'white',
                                    color: botButtonColor ? botButtonColor.slice(0, 7) : '', // Specify the font color
                                }}
                                onClick={closeLoginModal}
                                type="button"
                                className={classNames(
                                    'flex justify-center items-center w-90pc px-4 py-3 rounded-lg mx-auto mb-6 mt-2 font-bold shadow-lg',
                                    {
                                        'bg-sky-500': !botButtonColor,
                                    }
                                )}
                            >
                                {t('cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

LoginModal.propTypes = {};

export default LoginModal;
