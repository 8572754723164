import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Interweave from 'interweave';
import IntentText from '../component/MessageComponents/IntentText';
import { compileLiveAgentTextMessage, compileTextMessage } from '../utils/messageBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { editStatus } from '../redux/state/status';
import { useTranslation } from "react-i18next";

interface TextMessageProps {
    owner: 'user' | 'bot' | 'agent' | 'system' | 'null';
    message: any;
    name: string;
    time: string;
    isNewOwner: boolean;
    firstMessage?: boolean;
}

const TextMessage = (props: TextMessageProps) => {
    const { t } = useTranslation();

    const errorResponse = useSelector(
        (state: { systemMessages: { error_response: { message: string } } }) =>
            state.systemMessages.error_response
    );

    const systemMessages = {
        errorResponse: errorResponse && errorResponse.message ? errorResponse.message : t("error_message")
    };

    let HTMLMessage: any;
    if (
        props.owner === 'null' ||
        props.owner === 'system' ||
        props.owner === 'user' ||
        props.owner === 'bot'
    ) {
        HTMLMessage = compileTextMessage(props.message, t, systemMessages);
    } else if (props.owner === 'agent') {
        HTMLMessage = compileLiveAgentTextMessage(props.message);
    }

    let dispatch = useDispatch();

    const openFormModal = () => {
        dispatch(editStatus({ isForm: true }));
    };

    const botAvatar = useSelector(
        (state: { settings: { botAvatar: string } }) => state.settings.botAvatar
    );

    const agentAvatar = useSelector(
        (state: { settings: { agentAvatar: string } }) =>
            state.settings.agentAvatar
    );

    const botChatBoxBgColor = useSelector(
        (state: { settings: { botChatBoxBgColor: string } }) =>
            state.settings.botChatBoxBgColor
    );
    const botTextColor = useSelector(
        (state: { settings: { botTextColor: string } }) =>
            state.settings.botTextColor
    );

    const memberChatBoxColor = useSelector(
        (state: { settings: { memberChatBoxColor: string } }) =>
            state.settings.memberChatBoxColor
    );
    const memberTextColor = useSelector(
        (state: { settings: { memberTextColor: string } }) =>
            state.settings.memberTextColor
    );

    const botFontSize = useSelector(
        (state: { settings: { botFontSize: number } }) =>
            state.settings.botFontSize
    );

    const fontSizesRef = ['xs', 'sm', 'base', 'lg', 'xl'];
    const smallFontSizeRef = ['2xs', 'xs', 'sm', 'base', 'lg'];
    const fontSizes = [
        fontSizesRef[0 + botFontSize],
        fontSizesRef[1 + botFontSize],
        fontSizesRef[2 + botFontSize],
    ];
    const smallFontSizes = [
        smallFontSizeRef[0 + botFontSize],
        smallFontSizeRef[1 + botFontSize],
        smallFontSizeRef[2 + botFontSize],
    ];

    // console.log("HTMLMessage", HTMLMessage)

    return (
        <div
            className={classNames(
                'flex w-full items-start justify-start py-0.5 font-light z-10 px-2',
                {
                    'flex-row':
                        props.owner === 'bot' || props.owner === 'agent',
                },
                {
                    'flex-row-reverse': props.owner === 'user',
                }
            )}
        >
            {/* {props.isNewOwner && props.owner === 'user' && (
                <img
                    src=""
                    alt="My profile"
                    className={classNames(
                        'w-8 h-8 medium:w-12 medium:h-12 rounded-full mt-4'
                    )}
                    // className={classNames('rounded-full mt-4')}
                />
            )} 
             {!props.isNewOwner && props.owner === 'user' && (
                <img
                    src=""
                    alt="My profile"
                    className={classNames(
                        'w-8 h-0 medium:w-12 medium:h-0 rounded-full invisible mt-2'
                    )}
                    // className={classNames('rounded-full invisible mt-2')}
                />
            )}*/}
            {botAvatar && props.isNewOwner && props.owner === 'bot' && (
                // <div className="w-[24px] h-8 min-w-8 min-h-8 medium:w-12 medium:h-12 mt-4 rounded-full shadow-md overflow-hidden">
                //     <img
                //         src={botAvatar}
                //         alt="My profile"
                //         className={classNames(
                //             'w-[24px] h-8 medium:w-12 medium:h-12 object-fill'
                //         )}
                //     />
                // </div>
                <img
                    src={botAvatar}
                    alt="My profile"
                    className={classNames(
                        'w-8 h-8 medium:w-12 medium:h-12 object-fill mt-4 rounded-full shadow-lg'
                    )}
                    // className={classNames('rounded-full mt-4')}
                />
            )}
            {botAvatar && !props.isNewOwner && props.owner === 'bot' && (
                <img
                    src=""
                    alt="My profile"
                    className={classNames(
                        'w-8 h-0 medium:w-12 medium:h-0 rounded-full invisible mt-2'
                    )}
                    // className={classNames('rounded-full invisible mt-2')}
                />
            )}
            {agentAvatar && props.isNewOwner && props.owner === 'agent' && (
                <img
                    src={agentAvatar}
                    alt="My profile"
                    className={classNames(
                        'w-8 h-8 medium:w-12 medium:h-12 rounded-full mt-4 shadow-md'
                    )}
                    // className={classNames('rounded-full mt-4')}
                />
            )}
            {agentAvatar && !props.isNewOwner && props.owner === 'agent' && (
                <img
                    src=""
                    alt="My profile"
                    className={classNames(
                        'w-8 h-0 medium:w-12 medium:h-0 rounded-full invisible mt-2'
                    )}
                    // className={classNames('rounded-full invisible mt-2')}
                />
            )}
            <div
                className={classNames(
                    'w-full flex flex-col text-xs px-2',
                    {
                        'items-start':
                            props.owner === 'bot' || props.owner === 'agent',
                    },
                    { 'items-end': props.owner === 'user' }
                )}
            >
                {props.isNewOwner && (
                    <div
                        className={`text-${smallFontSizes[0]} small:text-${smallFontSizes[0]} medium:text-${smallFontSizes[1]} large:text-${smallFontSizes[2]} font-medium text-slate-500 mt-2`}
                    >
                        {`${props.firstMessage ? props.name : ''} `}
                        {props.time}
                    </div>
                )}
                {(props.owner === 'bot' || props.owner === 'agent') && (
                    <div
                        style={{
                            backgroundColor: `${
                                botChatBoxBgColor
                                    ? botChatBoxBgColor.slice(0, 7)
                                    : null
                            }`,
                            color: `${
                                botTextColor ? botTextColor.slice(0, 7) : null
                            }`,
                        }}
                        className={classNames(
                            `w-fit max-w-90pc text-${fontSizes[0]} font-base small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]} px-4 py-2 rounded-lg inline-block break-words shadow-md chat--disapear active flex flex-wrap rounded-tl-none whitespace-normal`,
                            {
                                'bg-slate-500': !botChatBoxBgColor,
                                'text-white': !botTextColor,
                            }
                        )}
                    >
                        {/* <Interweave content={HTMLMessage}></Interweave> */}
                        {HTMLMessage}
                    </div>
                )}
                {props.owner === 'user' && (
                    <div
                        style={{
                            backgroundColor: `${
                                memberChatBoxColor
                                    ? memberChatBoxColor.slice(0, 7)
                                    : null
                            }`,
                            color: `${
                                memberTextColor
                                    ? memberTextColor.slice(0, 7)
                                    : null
                            }`,
                        }}
                        className={classNames(
                            `w-fit max-w-90pc text-${fontSizes[0]} font-base small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]} px-4 py-2 rounded-lg inline-block break-words shadow-md chat--disapear active flex flex-wrap rounded-tr-none whitespace-normal`,
                            {
                                'bg-slate-500': !memberChatBoxColor,
                                'text-white': !memberTextColor,
                            }
                        )}
                    >
                        {/* <Interweave content={HTMLMessage}></Interweave> */}
                        {HTMLMessage}
                    </div>
                )}
            </div>
        </div>
    );
};

TextMessage.propTypes = {};

export default TextMessage;
