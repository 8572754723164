import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket } from '../hook/useSocket';
import { useSelector } from 'react-redux';
import { ChatInterface } from '../utils/interfaces';
import TextMessage from './TextMessage';
import ImageMessage from './ImageMessage';
import VideoMessage from './VideoMessage';
import TypingMessage from './TypingMessage';
import SystemMessage from './SystemMessage';
import { warning } from '../utils/console';
import Dialogs from '../component/DialogComponents/Dialogs';
import classNames from 'classnames';
import QueueModal from './Modal/QueueModal';
import AnnouncementBanner from '../component/announcement/AccouncementBanner';
import IntentSuggestionModal from './Modal/IntentSuggestionModal';

interface Props {
    height: number;
    bannerHeight: number;
    isIOS: boolean;
    isKeyBoardOpen: boolean;
    keyBoardHeight: number;
}

const Content = (props: Props) => {
    const socket = useSocket();

    const [typing, setTyping] =
        useState<'bot' | 'agent' | 'user' | 'system' | 'null'>('null');

    let isNewOwner: boolean = false;

    let currentOwner = 'null';

    const chats = useSelector((state: { chat: ChatInterface[] }) => state.chat);

    const isChatAlive = useSelector(
        (state: { status: { isChatAlive: Boolean } }) =>
            state.status.isChatAlive
    );

    const botChatBgColor = useSelector(
        (state: { settings: { botChatBgColor: string } }) =>
            state.settings.botChatBgColor
    );

    const bottomElement = useRef<null | HTMLDivElement>(null);


    const scrollToBottom = () => {
        bottomElement.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const announcementIsEnabled = useSelector(
        (state: { settings: { announcementIsEnabled: boolean } }) =>
            state.settings.announcementIsEnabled
    );

    const isBotTyping = useSelector(
        (state: { status: { isBotTyping: Boolean } }) =>
            state.status.isBotTyping
    );

    const processor = useSelector(
        (state: { settings: { processor: number } }) =>
            state.settings.processor
    );

    useEffect(() => {
        scrollToBottom();
        // if (chats.length > 0) setTyping(chats[chats.length - 1].owner);
    }, [chats]);

    // let [searchParams, setSearchParams] = useSearchParams();

    // const handleClick = () => {
    //     console.log(socket);
    //     var data = {
    //         eventType: 'connect',
    //         timestamp: '123',
    //         botId: '30',
    //         acctId: '5',
    //         dbProfile: 'mario.staging.1',
    //     };
    //     socket.send(JSON.stringify(data));
    // };

    return (
        <div
            // style={{backgroundColor: `${botChatBgColor?botChatBgColor.slice(0,7):''}`}}
            style={{
                backgroundColor: `${botChatBgColor?botChatBgColor.slice(0,7):''}`, 
                ...(props.isIOS ? (props.isKeyBoardOpen ? {
                    height: (props.keyBoardHeight - (isChatAlive?68:0) - props.bannerHeight - (announcementIsEnabled?30:0))
                }:{
                    height: (props.height - (isChatAlive?68:0) - props.bannerHeight - (announcementIsEnabled?30:0)) // Height for iOS
                }): {})           
            }}
            className={classNames('h-full w-full relative overflow-y-auto no-scrollbar duration-300', {
                'h-100pc rounded-b-xl': !isChatAlive,
                'bg-slate-200': !botChatBgColor
            })}
        >
            <div className={classNames("h-full overflow-auto no-scrollbar", {'pointer-events-none': !isChatAlive})}>
                {chats.map((item: ChatInterface, index) => {
                    isNewOwner = currentOwner != item.owner;
                    currentOwner = item.owner;

                    switch (item.owner) {
                        case 'bot':
                        case 'agent':
                        case 'user': {
                            switch (item.type) {
                                case 'text': {
                                    // console.log("ITEM", item)
                                    return (
                                        <TextMessage
                                            owner={item.owner}
                                            name={item.name}
                                            message={item.message}
                                            isNewOwner={isNewOwner}
                                            time={item.displayTime}
                                            firstMessage={item.firstMessage}
                                            key={index}
                                        />
                                    );
                                }
                                case 'image': {
                                    // console.log(
                                    //     'IMAGE HERE from CONTENT.TSX',
                                    //     item
                                    // );
                                    return (
                                        <ImageMessage
                                            owner={item.owner}
                                            name={item.name}
                                            message={item.message}
                                            isNewOwner={isNewOwner}
                                            time={item.displayTime}
                                            key={index}
                                        />
                                    );
                                }
                                case 'video':
                                    {
                                        // console.log(
                                        //     'VIDEO HERE from CONTENT.TSX',
                                        //     item
                                        // );
                                        return (
                                            <VideoMessage
                                                owner={item.owner}
                                                name={item.name}
                                                message={item.message}
                                                isNewOwner={isNewOwner}
                                                time={item.displayTime}
                                                key={index}
                                            />
                                        );
                                    }
                                    break;
                            }
                            break;
                        }
                        case 'system': {
                            // console.log('system message', item);
                            return (
                                <SystemMessage
                                    key={index}
                                    message={item.message.content}
                                />
                            );
                        }
                        default: {
                            warning(
                                Content.name,
                                'Owner not in [bot, agent, user, system], got owner and message:',
                                item.owner,
                                item.message
                            );
                            break;
                        }
                    }
                })}
                {/* <SystemMessage message="Mario has joined the chat. Mario has joined the chat. Mario has joined the chat. Mario has joined the chat." /> */}
                {processor === 2 && (
                    <TypingMessage owner={isBotTyping ? 'bot' : 'null'} name="mario" isNewOwner={false} />
                )}
                {/* <TypingMessage owner={"null"} name="Mario" isNewOwner={false} /> */}
                <div ref={bottomElement} className="h-20" />
            </div>
            <div className={classNames({'absolute w-full h-full top-0 bg-slate-200/50 rounded-b-xl': !isChatAlive})}></div>
            <Dialogs />
            {isChatAlive && <IntentSuggestionModal />}
        </div>
    );
};

Content.propTypes = {};

export default Content;
