import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setState, getState } from '../../utils/localStorage';

const initialState = {
    botId: "",
    enablePreChat: true,
    enablePrechatBanner: true,
    preChatBannerUrl: "", 
    greetingMessage: "",
    submitButtonText: "",
    usernamePlaceholder: ""
};


export const settingsSlice = createSlice({
    name: 'settingsState',
    initialState,
    reducers: {
        editSetting: (state: any, action) => {
            console.log("editSetting", action.payload.name, action.payload.value);
            state[action.payload.name] = action.payload.value;
        },
    },
});

// Action creators are generated for each case reducer function
export const { editSetting } = settingsSlice.actions;

export default settingsSlice.reducer;
