import React, { createContext, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSessionService } from '../hook/useSessionService';
import { addDialog } from '../redux/state/dialog';
import { editSession, ISessionState } from '../redux/state/session';
import { editStatus } from '../redux/state/status';
import { error } from '../utils/console';
import { SessionCacheInterface } from '../utils/interfaces';
import { get, post } from '../utils/rest';
import { type } from '@testing-library/user-event/dist/type';

export const CacheServiceContext = createContext({} as any);

interface IProps {
    acctId: string | number;
    botId: string | number;
    apiPath: string;
    children: any;
}

declare const window: any;

const CacheService = (props: IProps) => {
    const dispatch = useDispatch();
    const sessionService = useSessionService();

    const setCache = async (sessionId: string, data: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const cache = await getCache(sessionId)
                if (!cache) {
                    const res = await post(`${props.apiPath}/setCache`, {
                        data: {
                            sessionId: props.botId.toString() + sessionId,
                            data,
                        },
                    });
                    resolve(res);
                    console.log('cache response:', res);
                }
                else {
                    resolve('cache exist!');
                }
            }
            catch (e) {
                error(CacheService.name, 'Exception when verify token:', e);
                reject(e);
            }
        });
    };

    const setCacheByField = async (
        sessionId: string,
        field: string,
        value: string
    ) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (field == 'socketPaths'){
                    value = value.replaceAll('"', '');
                }
                const immutableFields  = ['chatId', 'sessionId', 'socketPaths', 'laAffinityToken', 'laSessionKey', 'laSessionId']
                let fieldValue:any = '';
                if (immutableFields.includes(field)) {
                    fieldValue = await getCacheByField(sessionId, field);
                    console.log('test_', field, fieldValue, typeof(fieldValue))
                }
                if (!immutableFields.includes(field) || immutableFields.includes(field) && !fieldValue) {
                    const res = await post(`${props.apiPath}/setCacheByField`, {
                        data: {
                            sessionId: props.botId.toString() + sessionId,
                            field,
                            value,
                        },
                    });
                    resolve(res);
                    console.log('cache response:', res);
                } else {
                    console.log('value already exist!', field, value, fieldValue);
                    console.log('value already exist!', field, value === fieldValue);
                    console.log('value already exist!', field, typeof(value), typeof(fieldValue));
                    resolve('value already exist!');
                }
            } catch (e) {
                //error(CacheService.name, 'Exception when verify token:', e);
                error(CacheService.name, 'Exception when setCacheByField:', e, [sessionId, field, value]);
                reject(e);
            }
        });
    };

    const setCachesByFields = async (data: object) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log('setCachesByFields', data);
                const res = await post(`${props.apiPath}/setCachesByFields`, {
                    data: data,
                });
                resolve(res);
                console.log('cache response:', res);
            } catch (e) {
                // error(CacheService.name, 'Exception when verify token:', e);
                error(CacheService.name, "Exception when setCacheByFields", e, [data] );
                reject(e);
            }
        });
    };

    const getCache = async (sessionId: string) => {
        console.log('check api path', props.botId, sessionId, props.apiPath);
        if (props.apiPath) {
            return new Promise(async (resolve, reject) => {
                try {
                    const res = await post(`${props.apiPath}/getCache`, {
                        data: {
                            sessionId: props.botId.toString() + sessionId,
                        },
                    });
                    resolve(res);
                    console.log('cache response:', res);
                } catch (e) {
                    error(CacheService.name, 'Exception when verify token:', e);
                    reject(e);
                }
            });
        }
    };

    const getCacheByField = async (sessionId: string, field: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await post(`${props.apiPath}/getCacheByField`, {
                    data: {
                        sessionId: props.botId.toString() + sessionId,
                        field,
                    },
                });
                resolve(res);
                console.log('cache response:', res);
            } catch (e) {
                error(CacheService.name, 'Exception when verify token:', e);
                reject(e);
            }
        });
    };

    const popCache = async (sessionId: string) => {
        //check
        console.log('pop cache', sessionId, props.apiPath);
        if (props.apiPath) {
            return new Promise(async (resolve, reject) => {
                try {
                    const res = await post(`${props.apiPath}/popCache`, {
                        data: {
                            sessionId: props.botId.toString() + sessionId,
                        },
                    });
                    resolve(res);
                    console.log('cache response pop:', res);
                } catch (e) {
                    error(CacheService.name, 'Exception when verify token:', e);
                    reject(e);
                }
            });
        }
    };

    const pushCache = async (sessionId: string, data: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await post(`${props.apiPath}/pushCache`, {
                    data: {
                        sessionId: props.botId.toString() + sessionId,
                        data,
                    },
                });
                resolve(res);
                console.log('cache response:', res);
            } catch (e) {
                error(CacheService.name, 'Exception when verify token:', e);
                reject(e);
            }
        });
    };

    const removeCache = async (sessionId: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await post(`${props.apiPath}/removeCache`, {
                    data: {
                        sessionId: props.botId.toString() + sessionId,
                    },
                });
                resolve(res);
                console.log('cache response:', res);
            } catch (e) {
                error(CacheService.name, 'Exception when verify token:', e);
                reject(e);
            }
        });
    };

    const loadCache = async (cache: SessionCacheInterface) => {
        return new Promise(async (resolve, reject) => {
            if (cache.hasOwnProperty('chatId')) {
                if (cache.username.trim().length > 0) {
                    resolve(
                        dispatch(
                            editSession({ name: 'chatId', value: cache.chatId })
                        )
                    );
                }else {
                    resolve(false)
                }
            }
            // if (cache.hasOwnProperty('username')) {
            //     if (cache.username.trim().length > 0) {
            //         resolve(
            //             dispatch(
            //                 editSession({
            //                     name: 'username',
            //                     value: cache.username,
            //                 })
            //             )
            //         );
            //     }else {
            //         resolve(false)
            //     }
            // }
        });
    };

    //Special
    const checkCache = async (
        sessionId: string,
        tabId: string,
    ) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await post(`${props.apiPath}/getCacheByField`, {
                    data: {
                        sessionId: props.botId.toString() + sessionId,
                        field: 'tabId',
                    },
                });
                const status = await post(`${props.apiPath}/getCacheByField`, {
                    data: {
                        sessionId: props.botId.toString() + sessionId,
                        field: 'status',
                    },
                });
                const expire = await post(`${props.apiPath}/getExpire`, {
                    data: {
                        sessionId: props.botId.toString() + sessionId,
                    },
                });
                console.log('check cache', sessionId, res, tabId);
                if (res && res != tabId) {
                    dispatch(addDialog({
                        task: 'forceClose',
                        type: 'action',
                    }));
                    endSessionWithoutLiveAgentChatend(props.botId.toString() + sessionId);
                    resolve(true)
                }
                const isNumber = (value: unknown): value is number => {
                    return !!value && typeof value === 'number';
                };

                if (isNumber(expire)) {
                    if (expire > 30 && expire < 120 && status !== 'active') {
                        window.location.reload();
                    }
                    if (expire <= 0) {
                        dispatch(addDialog({
                            task: 'timeout',
                            type: 'action',
                        }));
                        endSession(props.botId.toString() + sessionId);
                        resolve(true)
                    } else if (expire <= 120) {
                        dispatch(addDialog({
                            task: 'timeoutWarning',
                            type: 'action',
                        }));
                    }
                }
                resolve(false);
            } catch (e) {
                error(CacheService.name, 'Exception when verify token:', e);
                reject(e);
            }
        });
    };

    const refreshCache = async (sessionId: string) => {
        const res = await post(`${props.apiPath}/refreshCache`, {
            data: {
                sessionId: props.botId.toString() + sessionId,
            },
        });
    };


    const endSession = async (sessionId: any) => {
        console.log('end session', sessionId);
        dispatch(editStatus({ name: 'isChatAlive', value: false }))
        dispatch(editStatus({ name: 'isSessionAlive', value: false }))
        sessionService.removeCacheSession(props.acctId, props.botId);
        if(window.isRN){
            window.ReactNativeWebView.postMessage(JSON.stringify({key : "CHAT ENDED", value: ""}));
        }
        removeCache(sessionId);
    };

    const endSessionWithoutLiveAgentChatend = async (sessionId: any) => {
        console.log('end session without agent chatend', sessionId);
        dispatch(editStatus({ name: 'isChatAlive', value: false }))
        if(window.isRN){
            window.ReactNativeWebView.postMessage(JSON.stringify({key : "CHAT ENDED", value: ""}));
        }
    };

    return (
        <>
            <CacheServiceContext.Provider
                value={{
                    getCache,
                    getCacheByField,
                    popCache,
                    setCache,
                    setCacheByField,
                    setCachesByFields,
                    loadCache,
                    pushCache,
                    checkCache,
                    removeCache,
                    refreshCache,
                    endSession,
                }}
            >
                {props.children}
            </CacheServiceContext.Provider>
        </>
    );
};

export default CacheService;
