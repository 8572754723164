import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';

interface IProps {
    text?: string;
}

const AnnouncementBanner = (props: any) => {
    const [key, setKey] = useState(1);
    const width = window.innerWidth;
    const announcementRef: any = useRef(null);
    const announcementText = useSelector(
        (state: { settings: { announcementText: string } }) =>
            state.settings.announcementText
    );
    console.log(
        'annoucement banner width: ',
        width,
        announcementText.length,
        width < 480,
        announcementRef.current?.offsetWidth
    );
    const announcementBackgroundColor = useSelector(
        (state: { settings: { announcementBackgroundColor: string } }) =>
            state.settings.announcementBackgroundColor
    );
    const announcementTextColor = useSelector(
        (state: { settings: { announcementTextColor: string } }) =>
            state.settings.announcementTextColor
    );

    const botFontSize = useSelector(
        (state: { settings: { botFontSize: number } }) =>
            state.settings.botFontSize
    );

    const scrolling = useSpring({
        from: {
            transform: `translate(${
                announcementRef.current?.offsetWidth + 60
            }px,0)`,
        },
        to: {
            transform: `translate(-${
                announcementRef.current?.offsetWidth < 480
                    ? announcementText.length * 12
                    : announcementText.length * (12 + botFontSize * 2)
            }px,0)`,
        },
        config: {
            duration:
                announcementText.length < 100
                    ? 20000
                    : announcementText.length < 200
                    ? 40000
                    : announcementText.length < 300
                    ? 60000
                    : 80000,
        },
        reset: true,
        //reverse: key % 2 == 0,
        onRest: () => {
            setKey(key + 1);
        },
    });

    const fontSizesRef = ['xs', 'sm', 'base', 'lg', 'xl'];
    const smallFontSizeRef = ['2xs', 'xs', 'sm', 'base', 'lg'];
    const fontSizes = [
        fontSizesRef[0 + botFontSize],
        fontSizesRef[1 + botFontSize],
        fontSizesRef[2 + botFontSize],
    ];
    const smallFontSizes = [
        smallFontSizeRef[0 + botFontSize],
        smallFontSizeRef[1 + botFontSize],
        smallFontSizeRef[2 + botFontSize],
    ];

    return (
        <div
            style={{
                backgroundColor: announcementBackgroundColor
                    ? announcementBackgroundColor.slice(0, 7)
                    : '#fff',
                color: announcementTextColor
                    ? announcementTextColor.slice(0, 7)
                    : '#000',
                opacity: 0.95,
            }}
            key={key}
            className={`flex items-center justify-end h-[30px] min-h-[30px] medium:h-[32px] medium:min-h-[32px] large:h-[36px] large:min-h-[36px] w-full py-2 text-right no-scrollbar truncate text-xs small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]} -z-20`}>
            <animated.div ref={announcementRef} className="" style={scrolling}>
                {announcementText}
            </animated.div>
        </div>
    );
};

export default AnnouncementBanner;
