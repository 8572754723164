import {
    FunctionInterface,
    IntentLinkInterface,
    ReduxFunctionInterface,
} from './interfaces';
import Interweave from 'interweave';
import IntentText from '../component/MessageComponents/IntentText';
import HyperlinkText from '../component/MessageComponents/HyperlinkText';
import { editStatus } from '../redux/state/status';
import Button from '../component/Button';

export const compileTextMessage = (message: any, t: (key: string) => string, systemMessages: Record<string, string>) => {

    const replacementMap: Record<string, string> = {
        "[UPLOAD_DEPOSIT_BUTTON]": t('depositUploadButton'),
        "[LOGIN_BUTTON]": t('login'),
        "[ERROR_RESPONSE_MESSAGE]": systemMessages['errorResponse']
    };

    const placeholderPattern = new RegExp(
        Object.keys(replacementMap)
            .map(key => key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')) // Escape special regex characters
            .join('|') + '|\\[.*?_BUTTON\\]', 
        'g'
    );

    let compiledContent = message.content;
    const positionAdjustments: { adjustIndex: number, adjustment: number }[] = [];
    let cumulativeAdjustment = 0;
    let isMatchFound = false;
    
    compiledContent = compiledContent.replace(
        placeholderPattern,
        (match: string, offset: number) => {
            const replacement = replacementMap[match] || match;
            if (replacement !== match) {
                const adjustment = replacement.length - match.length;
                positionAdjustments.push({ adjustIndex: offset + cumulativeAdjustment, adjustment });
                cumulativeAdjustment += adjustment; // Update cumulative adjustment
                isMatchFound = true;
            }
            return replacement;
        }
    );

    let textMessageElements: any = [];

    let textString = compiledContent;

    let textSize = compiledContent.length;

    let textTransactionId = message.transactionId || ""

    let pointer: number = 0;

    if (message.links) {
        message.links.map(
            (link: IntentLinkInterface, index: number, arr: []) => {
                let startPosition = link.startPosition
                let endPosition = link.endPosition + 1
                const intentId = link.intentId;
                if (positionAdjustments.length > 0) {
                    for (const { adjustIndex, adjustment } of positionAdjustments) {
                        if (adjustIndex < startPosition) {
                            startPosition += adjustment;
                            endPosition += adjustment;
                        } else if (adjustIndex < endPosition) {
                            endPosition += adjustment;
                        }
                    }
                }
                if (index == arr.length - 1 && startPosition != 0) {
                    textMessageElements.push(
                        <Interweave
                            noWrap={true}
                            content={textString.substring(
                                pointer,
                                startPosition
                            )}
                        />
                    );
                    textMessageElements.push(
                        link.type == 'goToIntent' ? (
                            <IntentText
                                intentId={intentId}
                                text={textString.substring(
                                    startPosition,
                                    endPosition
                                )}
                            />
                        ) : 'hyperlink' ? (
                            <HyperlinkText
                                url={link.url}
                                text={textString.substring(
                                    startPosition,
                                    endPosition
                                )}
                                transactionId={textTransactionId}
                            />
                        ) : (
                            <span>Invalid Link</span>
                        )
                    );
                    textMessageElements.push(
                        <Interweave
                            noWrap={true}
                            content={textString.substring(
                                endPosition,
                                textSize
                            )}
                        />
                    );
                    console.log(
                        'textMessageElements 3',
                        message,
                        textMessageElements,
                        startPosition,
                        endPosition,
                        textSize
                    );
                    // textMessageElements.push(
                    //     <Button
                    //         fns={[
                    //             {
                    //                 type: 'redux',
                    //                 func: '',
                    //                 dispatchArg: {
                    //                     action: editStatus,
                    //                     value: { name: 'isForm', value: false },
                    //                 },
                    //             },
                    //         ]}
                    //     >
                    //         <Interweave content="click me" />
                    //     </Button>
                    // );
                } else if (index == 0 && startPosition != 0) {
                    textMessageElements.push(
                        <Interweave
                            noWrap={true}
                            content={textString.substring(0, startPosition)}
                        />
                    );
                    textMessageElements.push(
                        link.type == 'goToIntent' ? (
                            <IntentText
                                intentId={intentId}
                                text={textString.substring(
                                    startPosition,
                                    endPosition
                                )}
                            />
                        ) : 'hyperlink' ? (
                            <HyperlinkText
                                url={link.url}
                                text={textString.substring(
                                    startPosition,
                                    endPosition
                                )}
                                transactionId={textTransactionId}
                            />
                        ) : (
                            <span>Invalid Link</span>
                        )
                    );
                    pointer = endPosition;
                    console.log(
                        'textMessageElements 1',
                        message,
                        textMessageElements,
                        startPosition,
                        endPosition,
                        textSize
                    );
                } else if (index == 0 && startPosition == 0) {
                    textMessageElements.push(
                        link.type == 'goToIntent' ? (
                            <IntentText
                                intentId={intentId}
                                text={textString.substring(
                                    startPosition,
                                    endPosition
                                )}
                            />
                        ) : 'hyperlink' ? (
                            <HyperlinkText
                                url={link.url}
                                text={textString.substring(
                                    startPosition,
                                    endPosition
                                )}
                                transactionId={textTransactionId}
                            />
                        ) : (
                            <span>Invalid Link</span>
                        )
                    );
                    pointer = endPosition;
                    console.log(
                        'textMessageElements 2',
                        message,
                        textMessageElements,
                        startPosition,
                        endPosition,
                        textSize
                    );
                } else {
                    textMessageElements.push(
                        <Interweave
                            noWrap={true}
                            content={textString.substring(
                                pointer,
                                startPosition
                            )}
                        />
                    );
                    textMessageElements.push(
                        link.type == 'goToIntent' ? (
                            <IntentText
                                intentId={intentId}
                                text={textString.substring(
                                    startPosition,
                                    endPosition
                                )}
                            />
                        ) : 'hyperlink' ? (
                            <HyperlinkText
                                url={link.url}
                                text={textString.substring(
                                    startPosition,
                                    endPosition
                                )}
                                transactionId={textTransactionId}
                            />
                        ) : (
                            <span>Invalid Link</span>
                        )
                    );
                    pointer = endPosition;
                }
            }
        );
    } else if(isMatchFound){
        textMessageElements.push(
            <Interweave noWrap={true} key={0} content={textString} />
        );
    } else {
        //regex to replace <button> and </button>
        textString = textString.replace(/<button>/g, '<span>');
        textString = textString.replace(/<\/button>/g, '</span>');

        //regular expression to remove text starting with < and ending with >
        const content = message.content.replace(/<button>[^>]*<\/button>/g, '');
        textMessageElements.push(
            <Interweave noWrap={true} key={0} content={content} />
        );
        //regular expresstion to find string start with <button> and end with </button>
        const regex = /<button.*?>.*?<\/button>/g;
        const matches = message.content.match(regex);
        // console.log('matches', matches);
        if (matches) {
            matches.map((match: string, index: number, arr: []) => {
                // console.log('match', match, index);
                match = match.replace(/<button>/g, '');
                match = match.replace(/<\/button>/g, '');
                const [buttonKey, buttonMessage] = match.split(':');
                if (buttonKey && buttonMessage) {
                    textMessageElements.push(<Interweave content={'<br>'} />);
                    textMessageElements.push(
                        <Button
                            className={
                                'text-white font-medium rounded-lg text-sm px-2 py-1.5 m-1 text-left inline-flex bg-sky-500'
                            }
                            key={buttonKey}
                            fns={[
                                {
                                    type: 'redux',
                                    dispatchArg: {
                                        action: editStatus,
                                        value: {
                                            name: 'isChatRatingModal',
                                            value: true,
                                        },
                                    },
                                },
                            ]}
                        >
                            <Interweave content={buttonMessage} />
                        </Button>
                    );
                }
            });
        }
    }
    return textMessageElements;
};

// export const compileLiveAgentTextMessage = (message: any) => {
//     let textMessageElements: any = [];

//     let textString = message.content;
//     console.log("TEXT CHECKING message", message);
//     // find url with www in text
//     // const urlRegex = /([http]*[s]*[:\/\/]*www\.[^ ]*)/g;
//     //const urlRegex = /([http]*[s]*[:\/\/]*[a-z0-9A-Z-@]*\.[^ ]*)/g;
//     const urlRegex = /([http]*[s]*[:\/\/]*[-a-zA-Z0-9@:%._\+~#=]*\.[a-z]*)/g

//     // find matches with urlRegex and urlRegex2
//     const matches = textString.match(urlRegex);
//     //add https:// if url with www does not have http or https in it
//     if (matches) {
//         matches.map((url: string, idx: number) => {
//             if (url.includes('www') && !url.includes('http')) {
//                 console.log( "TEXT CHECKING GOES HERE 1");
//                 textString = textString.replace(
//                     url,
//                     `<a class="underline" href="${
//                         'https://' + url
//                     }" target="_blank">${url}</a>`
//                 );
//             } else if (url.includes('www') && url.includes('http')) {
//                 textString = textString.replace(
//                     url,
//                     `<a class="underline" href="${url}" target="_blank">${url}</a>`
//                 );

//                 console.log("TEXT CHECKING GOES HERE 2", textString);
//             } else if (url.includes('http')) {
//                 console.log("TEXT CHECKING GOES HERE 3");
//                 textString = textString.replace(
//                     url,
//                     `<a class="underline" href="${url}" target="_blank">${url}</a>`
//                 );
//             }

//             if (idx == matches.length - 1) {
//                 //textString = '<p>wfwqfqwfqwf</p><p><a class="underline" href="https://www.google.com" target="_blank">https://www.google.com</a></p>'
//                 textMessageElements.push(
//                     <Interweave noWrap={true} key={0} content={textString} />
//                 );
//             }
//         });

//         console.log("TEXT CHECKING 1", matches, "TEXT STRING", textString);
//     } else {
//         textMessageElements.push(
//             <Interweave noWrap={true} key={0} content={textString} />
//         );
//         console.log("TEXT CHECKING 2", matches, "TEXT STRING", textString);
//     }

//     console.log("TEXT CHECKING ELEMENTS", textMessageElements);
//     return textMessageElements;
//     //return textString;

//     // find url with https in text
//     // const urlRegex2 = /(http[s]?:\/\/[^ ]*)/g;

//     // const matches2 = textString.match(urlRegex2);

//     // console.log('matches2', textString);

//     // if (matches2) {
//     //     matches2.map((url: string) => {
//     // textString.replace(
//     //     /(http[s]?:\/\/[^\s]+)/g,
//     //     `<a class="underline" href="${url}" target="_blank">${url}</a>`
//     // );
//     //     });
//     // }

//     // textMessageElements.push(
//     //     <Interweave noWrap={true} key={0} content={textString} />
//     // );
//     // return textMessageElements;
// };

export const compileLiveAgentTextMessage = (message: any) => {
    let textMessageElements: any = [];
    let textString = message.content;

    const urlRegex = /([http]*[s]*[:\/\/]*[a-z0-9A-Z-@]*\.[^ ]*)/g;

    let textArray = textString.split(/\r?\n/);

    for (let i = 0; i < textArray.length; i++) {
        let matches = textArray[i].match(urlRegex);

        if(matches) {
            let tempArray = textArray[i].split(" ");

            for (let j=0; j<tempArray.length; j++) {
                let tempMatch = tempArray[j].match(urlRegex);

                if (tempMatch) {
                    tempMatch.map((url: string) => {
                    
                        if (url.includes('www') && !url.includes('http')) {
                            tempArray[j] = tempArray[j].replace(
                                url,
                                `<a class="underline" href="${
                                    'https://' + url
                                }" target="_blank">${url}</a>`
                            );
                        } else if (url.includes('www') && url.includes('http')) {
                            tempArray[j] = tempArray[j].replace(
                                url,
                                `<a class="underline" href="${url}" target="_blank">${url}</a>`
                            );
        
                        } else if (url.includes('http')) {
                            tempArray[j] = tempArray[j].replace(
                                url,
                                `<a class="underline" href="${url}" target="_blank">${url}</a>`
                            );
                        }
        
                    });
                }

            }

            textArray[i] = tempArray.join(" ");
        } else if (textArray[i] == '') {
            textArray[i] = '<br>'
        } 

        textArray[i] = '<p>' + textArray[i] + '</p>';

    }

    let final = textArray.join("");

    textMessageElements.push(
            <Interweave noWrap={true} key={0} content={final} />
        );

    return textMessageElements;

};